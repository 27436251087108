<template>
    <a-layout>
      <a-layout-header >     
        <div class="nd-layout_container nd-layout_normal nd-flex space-between" >
          <div class="logo">
              <a href="/"><img src="@/assets/images/logo.png" alt="logo"></a>
          </div>
         
          <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items"  class="custom-menu"/>
          <a-popover placement="bottom">
            <template #content>

              <div >
                <img src="@/assets/images/B2BWechat.jpg" width="90" height="90" style="float: left">
                <div style="padding-left:10px">
                  <span class="text-large">体验小程序</span>
                  <span class="text-large text-green">预订更轻盈</span>
                </div>
              </div>

            </template>
  
            <a-button type="link" :icon="h(WechatOutlined)">小程序</a-button>
          </a-popover>

        </div>

      </a-layout-header>

     
      <a-layout-content>
          <router-view />
      </a-layout-content>

      <a-layout-footer style="text-align: center;">
        <div class="CommonFooter">

          <div class="nd-layout_container nd-layout_normal footer_down">
              <img src="@/assets/images/logo.png" alt="">
              <span class="footer_text"  v-html="copyRight"></span>
          </div>
        </div>  
      </a-layout-footer>
    </a-layout>
  </template>
  
  <script setup>
  import { h, ref } from 'vue';
  import { TeamOutlined, AuditOutlined,DeploymentUnitOutlined, GlobalOutlined ,WechatOutlined} from '@ant-design/icons-vue';
  const copyRight=ref("Copyright@2017 - 2024 http://www.etxing.com 《壹同行》高端酒店预订B2B平台，是深圳春秋国旅旗下平台，并拥有所有权。 <a href='https://beian.miit.gov.cn' target='_blank'>粤ICP备11070406号</a> 公安备案号44030302000853");
  const current = ref(['mail']);
  const items = ref([
    {
      key: 'about',
      icon: () => h(TeamOutlined),
      label: h(
        'a',
        {
          href:'/aboutus',
        },
        '关于我们',
      ),
    },
    {
      key: 'register',
      icon: () => h(AuditOutlined),
      label: h(
        'a',
        {
          href:'/reg',
        },
        '申请加盟',
      ),
    },
    {
      key: 'agreement',
      icon: () => h(DeploymentUnitOutlined),
      label:h(
        'a',
        {
          href:'/agreement',
        },
        '合作协议',
      ) 
    },
    {
      key: 'cqwebsite',
      icon: () => h(GlobalOutlined),
      label: h(
        'a',
        {
          href: 'http://www.szcqly.com/',
          target: '_blank',
        },
        '春秋官网',
      ),
    }
  ]);
  </script>
  
  
  <style scoped>
  .head_box{position: relative; height: 85px;
  }

  

.ant-layout .ant-layout-header{background: #fff; height: 100%;}
.ant-layout .ant-layout-header .ant-menu{line-height:85px; }

.custom-menu .ant-menu-item-selected::after,
.custom-menu .ant-menu-item-active::after,
.custom-menu .ant-menu-item::after{border-bottom: none !important; }
.custom-menu .ant-menu-item-selected {
  color: #669933 !important; 
}
.custom-menu .ant-menu-item-active {
  border-bottom: none;
  color: #669933 !important; 
}
.custom-menu .ant-menu-item:hover {
  border-bottom: none;
  color: #669933 !important; 
}




.text-large {
  font-size: large;
  display: block; /* 确保每个 span 是块级元素，可以换行 */
}



  </style>
  