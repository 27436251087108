<template>
    
    <div class="nd-layout_container nd-layout_normal nd-layout_box aboutus">
        <a-typography-title :level="3" style="text-align: center;">深圳春秋国际旅行社有限公司简介</a-typography-title>
        <a-typography-paragraph >
            深圳春秋国际旅行社有限公司，自成立以来一直专注高端酒店批发预订业务,并以战略合作的高端酒店为核心，为全国旅行社同仁提供高品质的自由行度假产品及定制服务。
        </a-typography-paragraph>
        <a-typography-paragraph >
            深圳春秋国旅一直致力成为“高端自由行度假领导者”，旗下拥有高端品牌：
        </a-typography-paragraph>
        <a-typography-paragraph >
            <ul>
                <li>“春秋旅游” — 高端自由行产品批发商</li>
                <li>“壹 同 行” — 高端酒店预订B2B平台</li>
                <li>“探 世 界” — 高端自由行定制</li>
            </ul>
        </a-typography-paragraph>
        <a-typography-paragraph >
            深圳春秋深受全国旅行社同仁、各合作伙伴的支持及认可，同时获得各大高端国际酒店集团长期合作的机会，先后获得多项殊荣：
        </a-typography-paragraph>


        <a-timeline  mode="alternate">
            <a-timeline-item>
                <p>2013</p>
                <ul class="timeline-detail-list">
                    <li>荣获洲际酒店集团香港九龙东皇冠假日酒店销售冠军</li>
                    <li>荣获万豪酒店集团香港区域最佳销售奖和会议销售专家等奖项;</li>
                    <li>荣获深圳旅游协会授于的深圳市旅行社10大专项服务供应商</li>
                </ul>
            </a-timeline-item>
            <a-timeline-item>          
                <p>2014</p>
                <ul class="timeline-detail-list">
                    <li>荣获洲际酒店集团香港九龙东皇冠假日酒店销售冠军</li>
                    <li>荣获万豪酒店集团香港区域最佳销售奖和会议销售专家等奖项;</li>
                    <li>荣获深圳旅游协会授于的深圳市旅行社10大专项服务供应商</li>
                </ul> 
            </a-timeline-item>
            <a-timeline-item>          
                <p>2015</p>
                <ul class="timeline-detail-list">
                    <li>荣获洲际酒店集团香港九龙东皇冠假日酒店销售冠军</li>
                    <li>荣获万豪酒店集团香港区域最佳销售奖和会议销售专家等奖项;</li>
                    <li>荣获深圳旅游协会授于的深圳市旅行社10大专项服务供应商</li>
                </ul> 
            </a-timeline-item>
            <a-timeline-item>          
                <p>2016</p>
                <ul class="timeline-detail-list">
                    <li>荣获洲际酒店集团香港九龙东皇冠假日酒店销售冠军</li>
                    <li>签署了万豪酒店集团全球4000多家高端酒店的一级代理权和洲际酒店集团大中华区(含港澳台地区)的一级代理权;</li>
                    <li>荣获深圳旅游协会授于的深圳市旅行社最佳专项服务供应商</li>
                </ul> 
            </a-timeline-item>
            <a-timeline-item>          
                <p>2017</p>
                <ul class="timeline-detail-list">
                    <li>荣 获 国 际 航空 运 输 协 会(IATA)颁发的TIDS资质。</li>
                    <li>入选广东省旅行社行业协会评选广东省百强社</li>
                </ul> 
            </a-timeline-item>




        </a-timeline>
        <div class="about_index3">
                    <h1>联系我们</h1>
                    <div class="contact_us">
                        <div class="contact_us_left">
                            <p>欢迎联系我们，您可以：</p>
                            <p>1）咨询酒店预订、入住相关问题</p>
                            <p>2）申请注册账号，免费使用系统</p>
                            <p>3）洽谈业务合作等</p>
                            <p>上班时间：法定工作日 周一至周五 早09:00-18:00</p>
                        </div>
                        <div class="contact_us_right">
                            <p>全国服务电话：</p>
                            <p class="tel">0755-82283588</p>
                            <p>公司总部地址：</p>
                            <p class="site">深圳市罗湖区深南东路中建大厦11楼1111室</p>
                        </div>
                    </div>

                </div>


    </div> 
</template>

<script>

export default {
  name: 'AboutUs',
  data(){
    return{

    }
    
  },
  mounted() {

    document.title = this.$route.meta.title;
  }


}
</script>
<style scoped>
.aboutus{
    margin-top: 20px
}

.aboutus .about_index3 {
  padding: 20px 0;
  border: 1px solid #ddd;
  margin: 0 45px;
}
.aboutus .about_index3 h1 {
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  margin-bottom: 20px;
}
.aboutus .about_index3 .contact_us {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.aboutus .about_index3 .contact_us .contact_us_left {
  position: relative;
}
.aboutus .about_index3 .contact_us .contact_us_left::after {
  content: '';
  width: 1px;
  height: 100px;
  background: #ddd;
  position: absolute;
  top: 20px;
  right: -62px;
}
.aboutus .about_index3 .contact_us p {
  line-height: 30px;
}

</style>
    