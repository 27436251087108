<template>



  <div class="nd-layout_container nd-layout_normal">


    <div class="order nd-flex space-between">
      <a-typography-text >订单提交成功，请尽快付款！订单号:{{orderId}}</a-typography-text>
    
      <a-statistic :value="totalAmount"  :value-style="{ color: '#f80' }">
        <template #prefix>
          <span style="font-size: 14px; color: rgba(0, 0, 0, 0.88);">应付金额：</span>
        </template>
        <template #suffix>
          <span style="font-size: 14px;color: rgba(0, 0, 0, 0.88);">元</span>
        </template>
      </a-statistic>

    </div>


    <div class="nd-panel-body">

      <a-alert  type="warning" show-icon>
        <template #message>   
          <a-statistic-countdown
            :value="deadline"
            format="m分s秒"
            :valueStyle="{'fontSize':'13px','color':'#ff4d4f'}"
            @finish="countdownEnd"
          >        
            <template #prefix>
              <span style="color:#1a1b1a;"> 距离二维码过期还剩</span>

            </template>
            <template #suffix>
              <span style="color:#1a1b1a;">过期后需要重新下订单</span>
            </template>
        
          </a-statistic-countdown> 
        
        </template>
      </a-alert>


      <div class="payment">
        <!-- 微信支付 -->
        <div class="pay-weixin">
            <div class="p-w-hd">微信支付</div>
            <div class="p-w-bd" style="position:relative">

                <div class="p-w-box">
                    <div class="pw-box-hd">
                            <div class="shade" v-if="timeOut"><a id="pastDue">已过期，重新下单</a></div>
                            <div class="shade2"  v-if="paySuccess"><a id="paySuccess">支付成功，正在跳转！</a></div>
                            <QrcodeOutlined style="font-size: 300px;" v-if="timeOut||paySuccess"/>
                            <img v-else :src="codeUrl" width="298" height="298">
                    </div>
                    <div class="pw-box-ft nd-flex space-between">
                      <ScanOutlined  style="font-size: 35px;"/>
                      <div>请使用微信扫一扫<br>扫描二维码支付</div>
                      
                    </div>
                </div>
                <div class="p-w-sidebar"></div>
            </div>
        </div>
        <!-- 微信支付 end -->
        <!-- payment-change 变更支付方式 -->
        <div class="payment-change">
            <a class="pc-wrap" onclick="window.history.go(-1)">
                <i class="pc-w-arrow-left">&lt;</i>
                <strong>选择其他支付方式</strong>
            </a>
        </div>
        <!-- payment-change 变更支付方式 end -->
    </div>







    </div>

  </div>



</template>



<script>
import Cookies from 'js-cookie';
import {ScanOutlined,QrcodeOutlined} from '@ant-design/icons-vue';
// //import { Modal } from 'ant-design-vue';
//import { message } from 'ant-design-vue';
import router from '@/assets/router';
import dayjs from 'dayjs';
export default {
  name: 'HotelOrder', 
  data(){
    return{
      deadline: dayjs().valueOf(),
      orderId:"",
      codeUrl:"",
      totalAmount:0,
      timeOut:false,
      paySuccess:false
    }
    
  },
  mounted() {
    document.title = this.$route.meta.title;

  },
  created() {
    
    this.orderId=this.$route.query.out_trade_no;
    this.getOrderDetail()
    var content=Cookies.get("etxing_Content_"+ this.orderId)
    if(content!=undefined){
      this.deadline=dayjs(JSON.parse(content).expiresAt).valueOf()
      this.codeUrl=process.env.VUE_APP_BASE_URL+JSON.parse(content).content
    }else{
      this.timeOut=true
    }
  },
  components: { 
    ScanOutlined,QrcodeOutlined
  },
  computed: {


    },


  methods: {

  //获取订单信息
  async getOrderDetail() {  

    try {   
      const response = await this.$axios.get('/bookingDetail/'+ this.orderId );

      if (response.data.RetCode === "Success") {
            this.totalAmount=response.data.BookingDetail.TotalAmount
            if (response.data.BookingDetail.PayStatus == "1") {
                this.paySuccess=true;
                setTimeout(function () {
                  router.push({
                      name: 'OrderPaySuccess',
                      query: {OrderId:this.orderId}
                  });              
                }, 1000);
              }
            console.log(response.data.BookingDetail.RatePlans)

      }else{
      
        this.$error({
              title: '啊哦~',
              content:response.data.RetMessage,
              okType: 'danger',
              okText: '点击重新预定',
              onOk() {
                  router.go(-1) 
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);  
              },
            });

      }


    } catch (error) {

        this.$error({
                title: '啊哦~',
                content: '获取数据失败'+error,
                okType: 'danger',
                okText: '请刷新重试',
                onOk() {                 
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);  
                },
        });

    }
    },

    //倒计时结束
    countdownEnd:function(){
      this.timeOut=true
     
    },

  }
}
</script>
<style scoped>

.order{line-height: 65px; height: 65px;}
.p-w-hd {
  margin-bottom: 20px;
  font-size: 18px;
  font-family: "Microsoft Yahei";
}
.p-w-bd {
  padding-left: 150px;
  margin-bottom: 30px;
  zoom: 1;
}
.p-w-bd:after,
.pay-weixin:after {
  display: table;
  content: "";
  clear: both;
}
.p-w-box {
  float: left;
  width: 300px;
}
.pw-box-hd {
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  width: 298px;
  height: 298px;
}
.pw-box-hd .shade {

  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(37, 37, 37, 0.8);
}
.pw-box-hd .shade a {
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -80px;
  margin-top: -25px;
  display: inline-block;
  width: 160px;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  background: #ff6600;
  color: #fff;
  cursor: pointer;
}
.pw-box-hd .shade2 {

  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(37, 37, 37, 0.8);
}
.pw-box-hd .shade2 a {
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -80px;
  margin-top: -25px;
  display: inline-block;
  width: 160px;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  background: #71ab38;
  color: #fff;
  cursor: pointer;
}
.pw-box-ft {
  background: #ff6600;
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
  padding: 8px 70px;
  
}
.pw-box-ft p {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  font-weight: 700;
}
.p-w-sidebar {
  float: left;
  width: 379px;
  height: 421px;
  padding-left: 50px;
  margin-top: -20px;
  margin-left: 100px;
  background: url('@/assets/images/pc_icon_phone-bg.png') 50px 0 no-repeat;
}

</style>
