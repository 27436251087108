<template>
  <div @click="handleClickOutside">
    <a-input
    style="width: 100%;"
      v-model:value="searchKeyword"
      @change="debounceFilterResults"
      
      @focus="showSuggestions" 
      @focusout="hideSuggestions"
      @keydown="handleKeydown"
      :allowClear="true"
      placeholder="输入城市或酒店名称"
      ref="searchkeyword"
    />
    <div v-show="showableSuggestions" class="suggestions" @click.stop>
      <a-tabs v-if="filteredCities.length === 0 && filteredHotels.length === 0" v-model:activeKey="activeTab" @change="updateCityList" :tabBarStyle="tabBarStyle">
        <a-tab-pane
          v-for="(cities, tab) in categorizedCities"
          :key="tab"
          :tab="tab"
          style="padding: 15px;"
        >
          <ul class="tab-children-list">
            <li v-for="(city, index) in cities"
                :key="city.name"
               
                @click="selectItem(index, 'city', true)">
              {{ city.name }}
            </li>
          </ul>
        </a-tab-pane>
      </a-tabs>
      <div class="nd-suggestions" v-else>
        <div class="nd-suggestion-result" v-if="filteredCities.length > 0 ">

          
          <div class="nd-suggestion-result-list" >
            <div class="nd-suggestion-result-list-item"   style="display: flex; justify-content: space-between" 
            v-for="(city, index) in filteredCities"
            :key="city.name"
            @click="selectItem(index, 'city')"
            @mouseover="highlightItem(index)"
              @mouseleave="unhighlightItem"
            :class="{ 'highlighted': index === highlightedIndex }"
            >
            
              <div class="nd-suggestion-result-list-item_title"  v-html="city.highlightedName"></div>
           
              <div class="nd-suggestion-result-list-item_extra">城市</div>
              
            </div>

          
          </div>
        
        </div>
        <a-divider style="margin: 0;" />
        <div class="nd-suggestion-result"  v-if="filteredHotels.length > 0 ">


          <div class="nd-suggestion-result-list" >
            <div class="nd-suggestion-result-list-item"   style="display: flex; justify-content: space-between" 
            v-for="(hotel, index) in filteredHotels"
            :key="hotel.name"
            @click="selectItem(index+ filteredCities.length, 'hotel')"
            @mouseover="highlightItem(index+ filteredCities.length)"
              @mouseleave="unhighlightItem"
            :class="{ 'highlighted': index === highlightedIndex - filteredCities.length }"
            >
            
              <div class="nd-suggestion-result-list-item_title"  v-html="hotel.highlightedName"></div>
           
              <div class="nd-suggestion-result-list-item_extra">{{hotel.city}},{{ hotel.country }}</div>
              
            </div>

          
          </div>

<!--
          <div class="nd-suggestion-result-category" style="display: flex; justify-content: space-between">
            酒店
            <a-divider type="vertical" style="height: 100%;"/>
          </div>
          <div class="nd-suggestion-result-list" >
            <a v-for="(hotel, index) in filteredHotels"
              :key="hotel.name"
              :class="{ 'highlighted': index === highlightedIndex - filteredCities.length }"
              @click="selectItem(index + filteredCities.length, 'hotel')"
              style="display: flex; justify-content: space-between"
              >
              {{ hotel.name }}
              <span>1 results</span>
           </a>
          </div>
-->


        </div>




      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';  // 引入 lodash 的 debounce 函数
//import { getByteLength } from '@/assets/js/utils.js'; 
export default {
  props: {
    propsKeyword: {
          type: Object
    }
  },
  data() {
    return {
      tabBarStyle: {
        padding:'0 15px', 
        marginBottom:0
      },
      searchKeyword: '',
      activeTab: '国内热门城市',
      cities:[ { "code": 199, "name": "深圳", "type": "国内热门城市", "order": 100 }, { "code": 207, "name": "惠州", "type": "国内热门城市", "order": 100 }, { "code": 1, "name": "北京", "type": "国内热门城市", "order": 100 }, { "code": 73, "name": "上海市", "type": "国内热门城市", "order": 100 }, { "code": 341, "name": "香港", "type": "国内热门城市", "order": 999 }, { "code": 349, "name": "澳门", "type": "国内热门城市", "order": 998 }, { "code": 443, "name": "苏梅岛", "type": "海外热门城市", "order": 100 }, { "code": 441, "name": "普吉岛", "type": "海外热门城市", "order": 100 }, { "code": 439, "name": "曼谷", "type": "海外热门城市", "order": 100 }, { "code": null, "name": "清迈", "type": "海外热门城市", "order": 100 }, { "code": 436, "name": "芭堤雅", "type": "海外热门城市", "order": 100 }, { "code": 444, "name": "新加坡", "type": "海外热门城市", "order": 100 }, { "code": 455, "name": "芽庄", "type": "海外热门城市", "order": 100 }, { "code": 445, "name": "巴厘岛", "type": "海外热门城市", "order": 1 } ],
      hotels: [],
      showableSuggestions: false,
      highlightedIndex: -1,
      filteredCities: [],
      filteredHotels: [],
      lastSearchResults: {}, // 添加缓存
    };
  },
  watch: {

 
  },
  created() {
    //console.log("keywordcom")
   // console.log(this.propsKeyword)
    if(this.propsKeyword)
      this.searchKeyword=this.propsKeyword.name

  },
  computed: {
    categorizedCities() {
      const categories = {};
      const sortedCities = this.cities.slice().sort((a, b) => a.order - b.order);
      sortedCities.forEach(city => {
        if (!categories[city.type]) {
          categories[city.type] = [];
        }
        categories[city.type].push(city);
      });
      return categories;
    },
  },
  methods: {
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },
    highlightKeyword(text, keyword) {
      const escapedKeyword = this.escapeRegExp(keyword);
      const regex = new RegExp(`(${escapedKeyword})`, 'gi');
      return text.replace(regex, '<span style="color:#f32a4e">$1</span>');
    },

    async filterResults() {
      const keyword = this.searchKeyword.trim().toLowerCase();
      
      this.highlightedIndex = -1;

      if (this.lastSearchResults[keyword]) {
        // 使用缓存的结果
        const { cities, hotels } = this.lastSearchResults[keyword];
        this.filteredCities = cities;
        this.filteredHotels = hotels;
        this.showableSuggestions = true;
        return;
      }
     // const byteLength = getByteLength(keyword);
      if (keyword.length>1) {
        try {
          const response = await this.$axios.post('/SuggestionSearch', { keyword: keyword});
          const { cities, hotels } = response.data;
          //console.log(cities)
          this.filteredCities = cities.map(city => ({
            ...city,
            highlightedName: this.highlightKeyword(city.name, keyword)+','+city.country+' '+this.highlightKeyword(city.enName, keyword)+','+city.countryEn
          }));
          this.filteredHotels = hotels.map(hotel => ({
            ...hotel,
            highlightedName: this.highlightKeyword(hotel.name, keyword)+' '+this.highlightKeyword(hotel.enName, keyword)
          }));
          // 缓存结果
          this.lastSearchResults[keyword] = { cities: this.filteredCities, hotels: this.filteredHotels };
          this.showableSuggestions = true;
        } catch (error) {
          console.error('Error fetching search results:', error);
          this.filteredCities = [];
          this.filteredHotels = [];
          this.showableSuggestions = false;
        }
      } else {
        this.filteredCities = [];
        this.filteredHotels = [];
        this.showableSuggestions = true;

        //console.log(1)
      }
      //console.log(this.filteredCities)

    },
    debounceFilterResults: debounce(function() {
      this.filterResults();
    }, 500), // 500 毫秒的防抖延迟


    showSuggestions() {

      const keyword = this.searchKeyword.trim().toLowerCase();
      //console.log(keyword);
      //const byteLength = getByteLength(keyword);
      if(keyword.length<2){
        this.filteredCities = [];
        this.filteredHotels = [];
      }
  
      this.activeTab = '国内热门城市'; // 默认选中第一个tab
    
      if(keyword.length>1){
        this.filterResults();
      } else {
        this.showableSuggestions = true;
      }
      
      this.highlightedIndex = -1;
    },
    hideSuggestions(event) {
     // console.log()
      setTimeout(() => {
        if(!event.relatedTarget){
          this.showableSuggestions = false;
          if( this.searchKeyword=="" && this.propsKeyword) this.searchKeyword=this.propsKeyword.name  // 清空搜索框不选择其他结果失去聚焦的时候则补回原来的值
        }

         //console.log(this.propsKeyword)
      }, 200); // 延迟以保证点击事件能正常触发
    },
    handleClickOutside(event) {
      
      if (this.$refs.searchkeyword && !this.$refs.searchkeyword.$el.contains(event.target)) {
        this.showableSuggestions = false;
      }
    },
    focusInput() {
      this.$refs.searchkeyword.focus();
    },
    selectItem(index, type, isHotCity = false) {
      var typeId=0
      if (isHotCity) {
        const selectedCity = this.categorizedCities[this.activeTab][index];
        this.searchKeyword = selectedCity.name;
        typeId=parseInt(selectedCity.code)
      } else if (type === 'city') {
        this.searchKeyword = this.filteredCities[index].name;
        typeId=parseInt(this.filteredCities[index].code)
      } else if (type === 'hotel') {
        const hotelIndex = index - this.filteredCities.length;
        this.searchKeyword = this.filteredHotels[hotelIndex].name;
        typeId=parseInt(this.filteredHotels[hotelIndex].hotelId)
      }
      const keywordValue={
        type:type,
        id:typeId,
        name: this.searchKeyword 
      }
      this.$emit('selectKeyword', keywordValue); // 传递选择值给父组件
      this.showableSuggestions = false;
    },

    updateCityList() {
      this.highlightedIndex = -1;
    },

    highlightItem(index) {

      this.highlightedIndex = index ;

    },
    unhighlightItem() {
      this.highlightedIndex = -1;
    },

    handleKeydown(event) {
      if (!this.showableSuggestions) {
        this.showableSuggestions = true;
        return;
      }

      let direction=0
      if (event.key === 'ArrowDown') {
        // 向下键
        direction=1
      } else if (event.key === 'ArrowUp') {
        // 向上键
        direction=-1
      } else if (event.key === 'Enter') {
        // Enter键
        if (this.highlightedIndex < 0) return;

        const totalCities = this.filteredCities.length;
        const totalHotels = this.filteredHotels.length;

        if (this.highlightedIndex < totalCities) {
          this.selectItem(this.highlightedIndex, 'city');
        } else if (this.highlightedIndex - totalCities < totalHotels) {
          this.selectItem(this.highlightedIndex, 'hotel');
        }
                
        return

      }

      const totalItems = this.filteredCities.length + this.filteredHotels.length;
      this.highlightedIndex = (this.highlightedIndex + direction + totalItems) % totalItems;

      this.scrollToHighlighted();


    },



    scrollToHighlighted() {
      this.$nextTick(() => {
        const container = this.$el.querySelector('.suggestions');
        const highlighted = container.querySelector('.highlighted');

        if (highlighted) {
          const highlightedRect = highlighted.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          if (highlightedRect.top < containerRect.top) {
            container.scrollTop -= (containerRect.top - highlightedRect.top);
          } else if (highlightedRect.bottom > containerRect.bottom) {
            container.scrollTop += (highlightedRect.bottom - containerRect.bottom);
          }
        }
      });
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>
ul.tab-children-list {
  list-style-type: none;
  display:flex;
}
ul.tab-children-list li {
  padding: 0 8px;
  cursor: pointer;
}
ul.tab-children-list li:hover{background: #d7eecc}
.highlighted {
  background-color: #f0f0f0;
}

h3 {
  margin: 10px 0 5px;
}
.suggestions {
  position: absolute;
   margin-top: 5px;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
  width: 700px;
  max-height: 500px;
  overflow-y: auto;
}

.nd-suggestion-result{display: flex; }
.nd-suggestion-result-category{ flex: 1; padding: 15px;}
.nd-suggestion-result-list{ flex: 5; padding: 15px 0;}
.nd-suggestion-result-list .nd-suggestion-result-list-item {padding: 0px 15px; line-height: 24px; cursor: pointer;}
.nd-suggestion-result-list-item_title{flex:5}
.nd-suggestion-result-list-item_extra{flex:1;color: #999; font-size: 12px; text-align: right}
</style>
