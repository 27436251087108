<template>



  <div class="nd-layout_container nd-layout_normal" v-if="rateDataLoaded">
    <a-steps
      type="navigation"
      size="small"
      :items="[
        {
          title: '填写订单',
          status: 'process',

        },
        {
          title: '选择支付方式',
          status: 'wait',
        },
        {
          title: '完成',
          status: 'wait',

        },
      ]"
    ></a-steps>   






  <div class="order-layout nd-flex">


    <div class="order-layout_left nd-panel-body ">
      <a-form
      :model="formData"
      name="basic"
      autocomplete="off"
      @finish="onFinish"
      >
        <a-alert  type="warning" show-icon>
          <template #message>   
            <a-statistic-countdown
              :value="deadline"
              format="m分s秒"
              :valueStyle="{'fontSize':'13px','color':'#ff4d4f'}"
            >        
              <template #prefix>
                <span style="color:#1a1b1a;">请在</span>

              </template>
              <template #suffix>
                <span style="color:#1a1b1a;">内完成填写，以免房间被订完或者价格发生变化</span>
              </template>
          
            </a-statistic-countdown> 
          
          </template>
        </a-alert>


        <div  class="bookingInfo">
          <a-divider orientation="left" orientation-margin="0px" style="font-weight: 700;">预定信息</a-divider>

          <a-form-item label="酒店名称">
            <span>{{hotel.HotelName}}</span>
          </a-form-item>

          <a-form-item label="房间类型">
            <span>{{hotelRoom.RoomName}}</span>
          </a-form-item>

          <a-form-item label="间夜数">
            <span>{{ condition.RoomCount }} 间 {{nightCount}} 晚</span>
          </a-form-item>
          <a-form-item label="每日价格" :colon="false" :labelCol="{span: 24}">
          <table class="nd-list-table">
                  <thead>
                      <tr>
                          <td>日期</td>
                          <td>房价</td>
                          <td>成人早餐</td>
                          <td>份数</td>
                          <td>儿童早餐</td>
                          <td>份数</td>
                          <td>加床价</td>
                          <td>数量</td>
                          <td>小计</td>
                      </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item,index) in hotelRoom.RoomRatePlans[0].RoomPrices" :key="`room_price_`+index">   
                      <td>{{item.PriceDate}}</td>    
                      <td>{{item.Price}}</td>    
                      <td>{{item.BreakfastPrice==0?'不可加':extraConsumptions[index].breakfastPrice}}</td>    
                      <td>
                        <template v-if="item.BreakfastPrice==0">0</template>
                        <template v-else>                        
                          <a-select size="small" v-model:value="extraConsumptions[index].breakfastCount" >
                            <a-select-option value=0>0</a-select-option>
                            <a-select-option value=1>1</a-select-option>
                            <a-select-option value=2>2</a-select-option>
                            <a-select-option value=3>3</a-select-option>
                          </a-select>
                        </template>
                      </td>    
                      <td>{{item.BreakfastChildPrice==0?'不可加':extraConsumptions[index].breakfastChildPrice}}</td>    
                      <td>
                        
                        <template v-if="item.BreakfastChildPrice==0">0</template>
                        <template v-else>
                          <a-select size="small" v-model:value="extraConsumptions[index].breakfastChildCount" >
                            <a-select-option value=0>0</a-select-option>
                            <a-select-option value=1>1</a-select-option>
                            <a-select-option value=2>2</a-select-option>
                            <a-select-option value=3>3</a-select-option>
                          </a-select>
                        </template>
                      </td>    
                      <td>{{item.BedPrice==0?'不可加':extraConsumptions[index].bedPrice}}</td>    
                      <td>
                        
                        <template v-if="item.BedPrice==0">0</template>
                        <template v-else>
                          <a-select size="small"  v-model:value="extraConsumptions[index].bedCount" >
                            <a-select-option value=0>0</a-select-option>
                            <a-select-option value=1>1</a-select-option>
                            <a-select-option value=2>2</a-select-option>
                            <a-select-option value=3>3</a-select-option>
                          </a-select>
                        </template>
                      </td>  
                      <td>{{
                      item.Price
                      +this.extraConsumptions.filter(e=>e.priceDate==item.PriceDate).reduce((sum, item) => sum + item.breakfastPrice*item.breakfastCount, 0)
                      +this.extraConsumptions.filter(e=>e.priceDate==item.PriceDate).reduce((sum, item) => sum + item.breakfastChildPrice*item.breakfastChildCount, 0)
                      +this.extraConsumptions.filter(e=>e.priceDate==item.PriceDate).reduce((sum, item) => sum + item.bedPrice*item.bedCount, 0)
                      
                      }}</td>
                    </tr>
                  </tbody>
              </table>
            </a-form-item>

          <div ref="guests" ></div>
          <a-form-item 
            label="入住信息"
            help="每间至少填写1位成人姓名，所填姓名需与居住时所持证件一致。"
            :colon="false" :labelCol="{span: 24}"
             
            >
              <div v-for="(i,index) in parseInt(condition.RoomCount)" :key="`occupancy_item_`+index" class="nd-flex occupancy-item ng-star-inserted" style="margin-bottom: 10px;">
                <div class="occupancy-item_heading occupancy_heading">房间{{i}}</div>
                <div class="occupancy-item_content">
                  <div class="guest-panel">
                    <div class="occupancy_heading">
                      <label>*主入住人</label>
                    </div>
                    <div>
                      <a-form-item
                        :name="`guest_${index}`"
                        :autoLink="false"
                        :rules="[{
                          validator: validateGuest(index),
                          trigger: 'blur',
                        }]"
                      >
                        <a-input-group compact>
                          <a-input
                            v-model:value="formData[`guest_${index}_surname`]"
                          
                            style="width: 40%"
                            placeholder="姓氏，例 Zhang"
                          />
                          
                          <a-input
                            v-model:value="formData[`guest_${index}_firstname`]"
                            style="width: 60%"
                            placeholder="名字，例 San"
                          />
                        
                        </a-input-group>
                      </a-form-item>
                        
                      

                  
                    </div>
                    <div class="guest-panel_btn"  v-if="roomGuests[index]==1">
                      <a @click="addGuest(index)"><PlusCircleFilled /></a> 
                    </div>        
                  </div>

                  <div class="guest-panel" v-if="roomGuests[index]>1" >
                    <div class="occupancy_heading">
                      <label>其他入住人</label>
                    </div>
                    <div>

                      <a-form-item
                        :name="`guest_other_${index}`"
                        :autoLink="false"
                        :rules="[{
                          validator: validateGuest(index,true),
                          trigger: 'blur',
                        }]"
                      >
                      <a-input-group compact>
                        <a-input style="width: 40%" placeholder="姓氏，例 Zhang"   v-model:value="formData[`guest_other_${index}_surname`]" />
                        <a-input style="width: 60%" placeholder="名字，例 San"   v-model:value="formData[`guest_other_${index}_firstname`]" />   
                      </a-input-group> 
                    </a-form-item>
                    </div>
                    <div class="guest-panel_btn">
                      <a @click="delGuest(index)"><CloseCircleFilled /></a> 
                    </div>        
                  </div>


                </div>


              </div>

              
        
          </a-form-item>


          <a-form-item label="到店时间：" name="arrivalTime">
            <a-select
              v-model:value="formData.arrivalTime"
              style="width: 100px;"
              :options="ageOptions"
              
            ></a-select>
          </a-form-item>


        </div>

        
        <div class="orderInfo">
          <a-form-item 
          name="buyerNo"
          label="贵司订单号：" 
    
          >
            <a-input type="text" style="width:300px;"  v-model:value="formData.buyerNo"/>
            <a-typography-text class="ant-form-text" type="secondary">
              (如有需要,可以写上贵司订单编号)
            </a-typography-text>
          </a-form-item>

        </div>



        <div class="demand_info">
          <a-divider orientation="left" orientation-margin="0px" style="font-weight: 700;">其他需求</a-divider>
          <a-form-item>
            <p class="tips" style="font-size: 12px;"><WarningTwoTone two-tone-color="#52c41a" /> 以下需求会发送到酒店，但无法保证一定满足，实际以酒店当天安排为准。</p>
          </a-form-item>
          
        
          <a-form-item name="checkRemark">
            <a-checkbox-group  style="width: 100%">
              <a-row :gutter="[16,16]"> 
                <a-col :span="4" v-for="(item,index) in remarkArr" :key="index">
                  <a-checkbox :checked="item.checked" :value="item.name" @change="handleCheckRemark(index)">{{item.name}}</a-checkbox>
                </a-col>


              </a-row>
            </a-checkbox-group>
            
          </a-form-item>

        
          <a @click.prevent="toggleOtherRemark">
            备注其他
            <component :is="iconType" />
          </a>  
          <a-form-item name="otherRemark">
            <a-textarea v-if="OtherRemarkShow" placeholder="备注信息可填写140个字符" v-model:value="formData.otherRemark"></a-textarea>
          </a-form-item>
          
          


        </div>



        <div class="orderNotes">
          <a-divider orientation="left" orientation-margin="0px"  style="font-weight: 700;">温馨提示</a-divider>


          <a-descriptions bordered>
            <a-descriptions-item label="预订限制" :span="3">本平台所有酒店价格均不可用于OTA网站(含：淘宝，携程，艺龙，去哪儿等)上裸价销售，一经发现平台有权拒绝并且每单收取RMB5000元罚金。</a-descriptions-item>
            <a-descriptions-item label="入住方式" :span="3">请在前台办理入住时，务必保密预订渠道，直接出示相关证件，报名字办理入住即可。</a-descriptions-item>
            <a-descriptions-item label="入退时间" :span="3">入住时间&nbsp;&nbsp;14:00&nbsp;&nbsp;，退房时间&nbsp;&nbsp;12:00</a-descriptions-item>
            <a-descriptions-item label="出行提示" :span="3">如订单首晚未到店(No show)或延迟入住请务必联系我司，否则酒店有可能取消预定并不另行通知。
                            如出现到店无房、订单确认后酒店告知无法安排入住等情况，请及时与我司联系。<br/>
                            客服联系方式：0755-88866566（7×24小时）</a-descriptions-item>

          </a-descriptions>


        </div>

        <!-- 底部 开始-->
        <div class="orderFoot">
            <div class="debitInfo">
                <p style="color: rgb(255, 82, 86);">
                  <a-descriptions-item  v-if="hotelRoom.RoomRatePlans[0].CancellationPolicy.Returnable" :span="1">
                    预定成功后 {{hotelRoom.RoomRatePlans[0].CancellationPolicy.Description}}
                  </a-descriptions-item>
                  <a-descriptions-item v-else>预定成功后不可退改，noshow罚全段</a-descriptions-item>
                </p>
                <p>
                  <a-form-item name="agreement" >
                
                   <a-checkbox v-model:checked="formData.agreement"  > 我已知晓上述信息,并且接受壹同行的</a-checkbox>

                    <a href="serviceterm.html" style="color: #F80;" target="_blank">服务条款</a>
                  </a-form-item>


     
                </p>
            </div>


            <div class="payBox">
              <a-button :disabled="bookbtnLoading" :loading="bookbtnLoading" type="primary"   html-type="submit">下一步,支付</a-button>
            </div>
        </div>
        <!-- 底部 结束 -->

      </a-form>
    </div>






    <div class="order-layout_right">
          
      <a-card  class="custom-card" :bordered="false">
        <template #cover>
          <img alt="example" :src="hotel.HotelMainPhoto" height="160px"/>
        </template>
        <a-card-meta :title="hotel.HotelName">
          <template #description>{{ hotel.Address }}</template>
        </a-card-meta>
      </a-card>

      <a-card  class="custom-card" title="费用明细" :bordered="false">
        <table class="slide-right_price-list">
          <tr>
            <th>房价</th>
            <td>{{roomPriceTotal}}</td>
          </tr>
          <tr>
            <th>成人早餐</th>
            <td>{{ this.extraConsumptions.reduce((sum, item) => sum + item.breakfastPrice*item.breakfastCount, 0) }} </td>
          </tr>
          <tr>
            <th>儿童早餐</th>
            <td>{{ this.extraConsumptions.reduce((sum, item) => sum + item.breakfastChildPrice*item.breakfastChildCount, 0) }}</td>
          </tr>
          <tr>
            <th>加床价</th>
            <td>{{ this.extraConsumptions.reduce((sum, item) => sum + item.bedPrice*item.bedCount, 0) }}</td>
          </tr>

        </table>


        <a-divider />
        <div class="total-price nd-flex space-between">
          <div>总计：</div>
          <span>￥{{ TotalPrice }}</span>
        </div>
        <a-divider />
        <a-descriptions title="取消条款" >
          <a-descriptions-item  v-if="hotelRoom.RoomRatePlans[0].CancellationPolicy.Returnable" :span="1">
            {{hotelRoom.RoomRatePlans[0].CancellationPolicy.Description}}<br>
            {{cancellationPolicy(hotelRoom.RoomRatePlans[0].CancellationPolicy.CancellationPolicyRule)}}
          </a-descriptions-item>
          <a-descriptions-item v-else>不可退改，noshow罚全段</a-descriptions-item>

        </a-descriptions>



      </a-card>

    </div>

  </div>

  </div>

  <div class="loading_wrap" v-else>
    <a-spin tip="价格确认中，请稍等..." />
  </div>

</template>



<script>
import { PlusCircleFilled,CloseCircleFilled,DownOutlined,UpOutlined,WarningTwoTone} from '@ant-design/icons-vue';
//import { Modal } from 'ant-design-vue';
import { extractTimestamp } from '@/assets/js/utils.js';
import { message } from 'ant-design-vue';
import router from '@/assets/router';
import dayjs from 'dayjs';
export default {
name: 'HotelOrder', 
data(){
  return{
   
    ageOptions: Array.from({ length: 12 }, (_, i) => ({
        value: i+1 ,
        label:`${String(i * 2 + 2).padStart(2, '0')}:00`
      })),
    extraConsumptions:[],//附加项
    remarkArr:[{checked:false,name:"高楼层"},{checked:false,name:"立即到店"},{checked:false,name:"大床房"},{checked:false,name:"双床房"},{checked:false,name:"吸烟楼层"},{checked:false,name:"原房续住"},{checked:false,name:"安静房间"},{checked:false,name:"有窗"},{checked:false,name:"相邻房间"}],
    formData:{
      buyerNo: '',
      checkRemark:"",
      otherRemark: '',
      arrivalTime: 7,
      agreement:false
    },
    roomPriceTotal:0,
    iconType: 'DownOutlined',
    OtherRemarkShow: false,
    plainOptions:['Apple', 'Pear', 'Orange'],
    state :{checkedList: ['Apple', 'Orange']},
    deadline: dayjs().add(30,'minute').valueOf(),
    roomGuests:[],
    rateDataLoaded:false,
    bookbtnLoading:false,//下单按钮状态
    condition:{
        HotelId: 0,
        RatePlanId: 0,
        CheckIn:"",
        CheckOut: "",
        RoomCount: 1,
        AdultCount:2,
        ChildCount: 0,
        ChildAgeList:"",

      },
      hotel:{},
      hotelRoom:{},
  }
  
},
mounted() {
  document.title = this.$route.meta.title;
},
created() {
  this.getRate()
},
components: { 
  PlusCircleFilled,
  CloseCircleFilled,
  DownOutlined,
  UpOutlined,
  WarningTwoTone
},
computed: {
    urlParams() {
      const query = this.$route.query;
      //设置默认参数
      if(!query.checkInDate) query.checkInDate=dayjs().add(1, 'day').format('YYYY-MM-DD')
      if(!query.checkOutDate) query.checkOutDate=dayjs().add(2, 'day').format('YYYY-MM-DD')
      if(!query.roomCount) query.roomCount=1
      if(!query.adultCount) query.adultCount=2
      if(!query.childCount) query.childCount=0
      return  query;
    },
    nightCount(){
      const [start, end] = [dayjs(this.condition.CheckIn), dayjs(this.condition.CheckOut)];
      return end.diff(start, 'days')
    },
    TotalPrice(){
      const roomPrice=this.hotelRoom.RoomRatePlans[0].TotalPrice
      const breakfastPrice=this.extraConsumptions.reduce((sum, item) => sum + item.breakfastPrice*item.breakfastCount, 0)  * this.condition.RoomCount;

      const breakfastChildPrice=this.extraConsumptions.reduce((sum, item) => sum + item.breakfastChildPrice*item.breakfastChildCount, 0)  * this.condition.RoomCount;
      const bedPrice=this.extraConsumptions.reduce((sum, item) => sum + item.bedPrice*item.bedCount, 0)  * this.condition.RoomCount;
      const discountPrice=this.hotelRoom.RoomRatePlans[0].Discount
      return roomPrice+breakfastPrice+breakfastChildPrice+bedPrice-discountPrice
    },

  },
methods: {
  cancellationPolicy(cancellationPolicyRule){
      let ruleDescription=""
      if(cancellationPolicyRule.length>0){
       let cancelTime=dayjs(extractTimestamp(cancellationPolicyRule[0].CancelTime)).format('YYYY-MM-DD HH:mm:ss') 
          ruleDescription+=`${cancelTime}(UTC+8)之后不可修改或取消，否则扣取${cancellationPolicyRule[0].DeductionType==0?'总额':'首晚'}${cancellationPolicyRule[0].DeductionPercent}%房费，noshow罚全段`
      }
      return ruleDescription
    },

  onFinish(values) {
  
    if(!values.agreement){
      message.info('需要同意服务条款，才能预订');
      return;
    }

    this.checkRate().then(result => {
     if(result) {
      this.b2bBookings(values)
      //console.log('Success:', values);
      
     }else{
      message.info('已变价');
     }
    }).catch(error => {
      console.error("Error checking rate:", error);
      message.info(`已变价${error}`);
      return;
    });
    


   
  },

  validateGuest(index, isOther = false) {
    return () => {
      const surname = isOther ?  this.formData[`guest_other_${index}_surname`] : this.formData[`guest_${index}_surname`];
      const firstname = isOther ? this.formData[`guest_other_${index}_firstname`] : this.formData[`guest_${index}_firstname`];
      //console.log('surname:', surname);
      if (!surname || !firstname) {
        this.$nextTick(() => {
          const element = this.$refs.guests;
      
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        });
        return Promise.reject('请输入完整的姓名!');
      } else {
        if (isOther) {
          this.formData[`guest_other_${index}`]=`${surname}!${firstname}`;
        } else {
        this.formData[`guest_${index}`]=`${surname}!${firstname}`;
        }
        return Promise.resolve();
      }
    };
  },
    //获取酒店价格
  async getRate() {  
   // console.log('formData:', this.formData);
    var queryParams=this.urlParams

    //console.log("queryParams:",queryParams)  

    this.condition.HotelId=parseInt(queryParams.hotelId)
    this.condition.RatePlanId=parseInt(queryParams.roomRatePlanId)
    this.condition.CheckIn=queryParams.checkInDate
    this.condition.CheckOut=queryParams.checkOutDate
    this.condition.RoomCount=queryParams.roomCount
    this.condition.AdultCount=queryParams.adultCount
    this.condition.ChildCount=queryParams.childCount
    if(queryParams.childCount>0)
      this.condition.ChildAgeList =queryParams.childAgeList

    this.roomGuests=  Array.from({ length: this.condition.RoomCount }, () => (1))//默认每个房间1位成人
    //console.log(this.roomGuests)
    try {   
      const response = await this.$axios.post('/hotelsV3',this.condition );
      
      if(response.data.Hotels.length>0 && response.data.Hotels[0].Rooms.length>0){

            this.hotel=response.data.Hotels[0] 
   
            this.hotelRoom=response.data.Hotels[0].Rooms[0]
            this.roomPriceTotal=this.hotelRoom.RoomRatePlans[0].TotalPrice
            this.setExtraConsumptions(response.data.Hotels[0].Rooms[0].RoomRatePlans[0].RoomPrices)//设置附加项
            this.rateDataLoaded = true;
      }else{
       
        this.$error({
              title: '啊哦~',
              content: '很抱歉，该房型已经预定完了，换个房型试试吧！',
              okType: 'danger',
              okText: '返回重新选择',
              onOk() {
                  router.go(-1) 
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);  
              },
            });

      }


    } catch (error) {

      this.$error({
              title: '啊哦~',
              content: '获取数据失败',
              okType: 'danger',
              okText: '请刷新重试',
              onOk() {                 
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);  
              },
      });

    }
  },
  //验证价格是否变价
  checkRate() {
      return new Promise((resolve, reject) => {
        const queryParams = this.urlParams;

        this.condition.HotelId = parseInt(queryParams.hotelId || 0);
        this.condition.RatePlanId = parseInt(queryParams.roomRatePlanId || 0);
        this.condition.CheckIn = queryParams.checkInDate || '';
        this.condition.CheckOut = queryParams.checkOutDate || '';
        this.condition.RoomCount = queryParams.roomCount || 0;
        this.condition.AdultCount = queryParams.adultCount || 0;
        this.condition.ChildCount = queryParams.childCount || 0;
        if (this.condition.ChildCount > 0) {
          this.condition.ChildAgeList = queryParams.childAgeList || [];
        }

        this.roomGuests = Array.from({ length: this.condition.RoomCount }, () => 1); // 默认每个房间1位成人

        const request = {
          HotelId: this.condition.HotelId,
          RatePlanId: this.condition.RatePlanId,
          CheckIn: this.condition.CheckIn,
          CheckOut: this.condition.CheckOut,
          RoomCount: this.condition.RoomCount,
          AdultCount: this.condition.AdultCount,
          ChildCount: this.condition.ChildCount,
        };

        this.$axios.post('/hotelsV3', request)
          .then(response => {
            if (response.data.RetCode === "Success") {
              const totalPrice = response.data.Hotels[0].Rooms[0].RoomRatePlans[0].RoomPrices
                .reduce((sum, item) => sum + item.Price, 0) * this.condition.RoomCount;

              if (totalPrice !== this.roomPriceTotal) {
                resolve(false);
              } else {
                resolve(true);
              }
            } else {
              resolve(false);
            }
          })
          .catch(error => {
            console.error("Error fetching hotel rates:", error);
            reject(false);
          });
      });
    },

  async b2bBookings(formData){
    console.log('formData:', formData);
    this.bookbtnLoading = true;
    var data = {
        "RatePlanId": this.condition.RatePlanId,
        "CheckIn": this.condition.CheckIn,
        "CheckOut": this.condition.CheckOut,
        "RoomCount": this.condition.RoomCount,
        "ArrivalTime": formData.arrivalTime,
        "Guests":this.transformGuests(formData),
        "BuyerConfirmNo": formData.buyerNo,
        "OrderAmount": this.TotalPrice,
        "Remark": formData.checkRemark+formData.otherRemark,
        "ExtraConsumptions":this.extraConsumptions.map(price => {
          return {
            PriceDate: price.priceDate,
            Count: parseInt(price.breakfastCount),
            ChildCount: parseInt(price.breakfastChildCount),
            BedCount: parseInt(price.bedCount),
          };
        })
      };

      try {   
      const response = await this.$axios.post('/b2bBookings',data );
      console.log(response.data)
      if (response.data.RetCode === "Success") {

        this.$success({
              title: '恭喜您',
              content: '订单提交成功',
              okText: '去支付订单',
              onOk() {
                router.push({
                      name: 'HotelOrderPayment',
                      query: {OrderId:response.data.OrderId}
                  });
                 
 
              },
          }); 
      }else{
        this.$error({
              title: '啊哦~',
              content: response.data.Error.ErrorMessage,
              okType: 'danger',
              okText: '请刷新重试',
              onOk() {                 
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);  
              },
          });
      }


    } catch (error) {

      this.$error({
              title: '啊哦~',
              content: '获取数据失败',
              okType: 'danger',
              okText: '请刷新重试',
              onOk() {                 
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);  
              },
      });

    }finally {
              this.bookbtnLoading = false;
          }



  },

  transformGuests(formdata) {
    const guests = [];
    for (const key in formdata) {
        if (key.startsWith('guest_')) {
            const [lastName,firstName] = formdata[key].split('!');
            guests.push({ LastName: lastName, FirstName: firstName });
        }
    }
    return guests;
  },

  setExtraConsumptions(roomPrices) {
      this.extraConsumptions= roomPrices.map(price => {
          return {
            priceDate: price.PriceDate,
            breakfastPrice: price.BreakfastPrice,
            breakfastChildPrice: price.BreakfastChildPrice,
            bedPrice: price.BedPrice,
            breakfastCount: 0,
            breakfastChildCount: 0,
            bedCount: 0
          };
      });
  },



  handleCheckRemark(index){
    this.remarkArr[index].checked = !this.remarkArr[index].checked
    this.formData.checkRemark = this.remarkArr.filter(item => item.checked).map(item => item.name).join(',')
    //console.log(this.remarkArr)

  },
  addGuest(index){
    this.roomGuests[index]++
  },
  delGuest(index){
    if(this.roomGuests[index]>1)
      this.roomGuests[index]--
  },
  toggleOtherRemark() {
   
    if(this.OtherRemarkShow){
      this.OtherRemarkShow = false
      this.iconType = 'DownOutlined'
    }
     else{
      this.OtherRemarkShow = true
      this.iconType = 'UpOutlined'
     }
    }
}
}
</script>
<style scoped>

.loading_wrap {
  text-align: center;
  height: 500px;
  line-height: 500px;
}
.order-layout_left{flex: 5;margin-right: 20px;}
.order-layout_right{flex: 2}
.nd-list-table thead>tr>td, .nd-list-table tbody>tr>td {
    padding: 10px;
}
.occupancy-item_content{
  background: #f9f9f9;padding: 10px; width: 100%;
}
.occupancy-item_heading {
    width: 80px;
}
.occupancy_heading {
    margin-right: 8px;
    line-height: 30px;
    text-align: right;
    color: #676f86;
}
.occupancy-item_content .guest-panel{  display:  table-row;}
.occupancy-item_content .guest-panel>div{

  display: table-cell;
    vertical-align: top;
    padding-bottom: 4px;    padding-right: 8px
}
.guest-panel_btn {
    -webkit-user-select: none;
    user-select: none;
    font-size: 16px;
    line-height: 32px;
    cursor: pointer;
    padding: 0 8px;
    margin-right: -8px;
    color: #1f2b4b;
}

.total-price span{font-size: 18px;color: #f60; font-weight: 700;}
.custom-card  {
  border-radius: 0 !important;
}

.custom-card .ant-card-cover img {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.custom-card .ant-card-meta {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.slide-right_price-list{width: 100%;}
.slide-right_price-list th{
  font-weight: 400;
    text-align: left;

    width: 90px;
    padding-right: 20px;
    vertical-align: top;
}
.slide-right_price-list td{    
  color: #f60;
  text-align: right;
    padding-bottom: 4px;
    vertical-align: top;
  }

.orderFoot {
    background: #f9f9f9;
    padding: 0 20px;
    border-top: 1px solid #ddd;
}

.debitInfo {
    border-bottom: 1px dotted #ddd;
    padding: 10px 0 20px;
}

.debitInfo p {
    font-size: 14px;
    line-height: 30px;
    color: #666;
}
.payBox {
    text-align: center;
    padding: 30px 0;
}

</style>
