<template>
    
    <div class="nd-layout_container nd-layout_normal nd-layout_box apply_content">
<div class="apply_info">
        <div class="agreements">
                <h1>
                    春秋《壹同行》分销系统对接合作与业务结算挂账协议
                </h1>
                <p>甲方：深圳春秋国际旅行社有限公司 &nbsp;&nbsp;&nbsp;&nbsp;（以下简称“甲方”）</p>
                <p>乙方：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（以下简称“乙方”）
                </p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;甲方对《壹同行》分销API系统享有完全的所有权与知识产权。甲、乙双方经平等协商，本着互惠互利的原则，根据相关法律、法规的规定，达成并签订春秋《壹同行》API分销系统对接合作与业务结算挂账协议（以下简称“本协议”），就乙方与甲方春秋API分销系统对接，及甲、乙双方业务结算挂账等相关事宜达成如下约定，以资共同遵守。
                </p>
                <h2>第一条 甲乙双方权利与义务</h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
                    甲方在此授予乙方一个非独占的、不可转让的、有限的使用许可权利；即甲方向乙方提供分销系统公司登陆账号：<span></span>，管理员用户名的初始密码：<span></span>，初始密码由《壹同行》系统自动生成并同时以电子邮件形式通知到乙方指定的电子邮箱：<span></span>；管理员姓名为:<span></span>；联系电话为：<span></span>；乙方应在收到登陆账号与初始密码的第一时间修改初始密码并保持密码的机密性。乙方可凭管理员用户名及密码在《壹同行》系统中创建乙方员工用户名与密码。乙方负责确保其员工保持密码的机密性。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
                    凡通过乙方管理员用户名及密码在甲方壹同行分销系统进行的交易，均视为乙方交易及授权行为；通过乙方管理员用户名及密码、乙方员工用户名及密码发送的服务请求，均视为乙方行为，由乙方承担全部责任。根据密码等电子信息为乙方授权管理的预定确认、账单结算、网上支付等各类交易，交易所产生的电子信息记录均为该项交易的有效凭据。乙方应在安全的技术和环境下在互联网上使用壹同行分销系统，否则乙方须对在互联网上使用所导致的风险和损失自行承担责任。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
                    甲方许可乙方通过互联网使用其分销系统，进行酒店预订业务。甲方保留其对于分销系统的其他权利，包括但不限于：修改权、保护作品完整权等。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.
                    乙方应该在甲方的技术指导下正确有效的使用该系统，不得恶意使用（包括但不限于擅自开发有碍系统正常运行的软件）。甲方有权定期、不定期调查\评判乙方是否恶意使用系统;对乙方恶意使用系统的行为，甲方有权采取包括但不限于终止此服务\终止使用许可权等。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.
                    甲方应准确、及时地处理乙方发来的服务请求事项并及时反馈。除非获得甲方书面许可同意取消某项请求的，经系统发来的或甲方确认的乙方服务请求事项均不得取消。
                    任何未在甲方壹同行分销系统完成酒店预定即出现在酒店要求入住的情况（“go-show”），甲方一律不承担责任。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.
                    甲方保证对系统操作或书面确认的各预定内容负责；通过其他方式（包括QQ\微信\电话等）进行的回复只作参考用途。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.
                    乙方通过甲方预定系统进行线上酒店预订及旅游产品与服务预定，由于酒店及旅游产品与服务本身的价格调整，甲方有权随时更改壹同行网内的产品售价；甲方对此不另行通知。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.
                    乙方对订房单已作“保证入住”或已经支付费用给甲方的，因甲方责任致使客人不能按预定日期入住所定酒店的，甲方应及时通知乙方协商解决，由甲方安排同地段或同等级的酒店给乙方的客人入住；如协商不成，甲方应退还乙方已支付的费用。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.
                    乙方保证对其账号在系统所确认的一切预定记录内容认同并负责。通过乙方员工用户名及密码发送的服务请求均视为乙方的行为，由乙方承担全部责任。乙方应避免出现下列情况；一旦出现下列情况，由乙方承担全部损失及责任：
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （1）乙方员工离职前所产生的服务请求（各项业务），离职交接时未与乙方确认，乙方在员工离职后才发现的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （2）乙方员工离职后，乙方未及时对离职员工的账号做删除处理，造成离职员工仍使用该账号所产生的服务请求（订房等各项业务的）；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （3）乙方员工在使用账号时操作失误，做出与实际预定不相符的预定指令，所造成的房款差额部分；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （4）凡使用乙方账号及密码进行的交易，均视为乙方授权所为；依据密码产生的电子信息记录，包括乙方授权办理的预定确认、帐单结算、网上支付等各类交易所产生的电子信息记录，均为该项交易的有效凭据。乙方应在保证技术和使用环境均安全的互联网上使用本系统，否则因此所导致的风险和损失由乙方自行承担责任。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.
                    甲方对分销系统中信息处理过程的安全性、保密性、实时性负责；如经核实确属甲方系统安保问题的，甲方将承担因此项失责对乙方造成的实际经济损失。因不可抗力原因造成损失的，甲方免除本条款所述责任，但应向乙方提供书面证明资料并协助乙方寻求补救办法。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11.
                    甲方提供优质高效服务，乙方可通过电话或者电邮形式向甲方指定的服务专员进行相关技术咨询。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12.
                    甲方有权根据系统升级等需要暂时中止提供服务，但应提前在系统公示，并预告恢复正常使用的日期。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13.
                    乙方通过甲方进行线下预定，由于香港、澳门、境外地区酒店价格浮动较大，乙方需预定酒店时应在下单前先与甲方确认所需预订期间的酒店价格；乙方的酒店预订价格以甲方提供的确认单为准。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14.
                    线下预定酒店。乙方可通过确认预订计划书或指定有效签名及盖章订房单以传真方式或电子文档等形式发送给甲方进行酒店预订；乙方应在订单上注明：预订酒店所在城市的名称、酒店名称、客人姓名（中宾应以拼音名字为准；如客人是外宾的，应特别注明是外宾身份并提供准确的英文名字）、所订房间类别、数量、抵离日期及客人特别要求；甲方收到乙方订单后应尽快确认订单并回传。订单确认后，乙方应承担酒店预订所产生的一切费用；如乙方不认可或更改已确认之订单的，由此产生的一切经济责任由乙方承担。预订之后，成功确认的（国内、香港、澳门、境外等地区）酒店订单，不接受任何形式取消或更改入住人姓名、不得提前退房（特殊情况除外）；如出现前述任一情况的，乙方应按已确认订单进行全段付款；如出现乙方客人“NOSHOW”的情况，乙方仍有责任向甲方支付确认订单之房费。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15.
                    线下预定酒店更改及取消。乙方需要对已确认订单进行任何变更或取消的，均应以书面形式提前通知甲方；乙方不得以任何名义直接与酒店或供应商申请更改或取消；乙方自行与酒店或供应商直接更改或取消的，由此产生的一切费用和损失由乙方自行承担。甲方接到乙方的书面更改通知后应尽力协助乙方进行变更或取消事宜；客人入住后要求变更或取消的，经甲方与酒店进行相关事宜确认，或乙方客人确实属于提前离店并未产生任何额外费用的，甲方可向乙方出具变更或取消的确认通知。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;16.
                    甲方提供的所有酒店产品，不论是通过线上方式或/和通过线下方式进行，凡是属于确认状态或/和确认单的，即表示订单已确认。如出现确认状态或\和确认单的，但出现满房，由甲方承担相应责任，处理方法如下：
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;16.1
                    订单确认后，酒店无法确认所订房型，客人尚未到店无法正常安排原房型入住时:
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （1）提供原预订酒店免费升级房间入住，甲方承担由此产生全部差价损失（合计不高于首晚房费的30%）。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （2）若原预订酒店不能提供原标准以上级别的房间，甲方将安排客人入住不低于原预订酒店标准的其他酒店或以上房型，甲方承担由此产生的交通及房价差价费用（合计不高于首晚房费的30%）。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （3）若无法安排其他酒店或推荐酒店客人不接受，甲方将启动赔付，总赔付标准最高为原预订酒店所付首晚房费的30%。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;16.2
                    订单确认后到店无房，客人已到店无法正常安排原预订房型入住时：
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （1）提供原预订酒店免费升级房间入住，甲方承担由此产生全部差价损失。（合计不高于首晚房费）。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （2）若原预订酒店不能提供原预订标准房间以上级别的房间，甲方将安排客人入住不低于原预订标准的其他酒店或以上房型，甲方承担由此产生的交通及房价差价费用（合计不高于首晚房费）安排客人入住。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （3）若无法安排其他酒店或推荐酒店客人不接受，甲方将启动赔付，赔付标准最高为原预订单所付酒店的首晚房费。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;16.3
                    订单确认后到店无房，客人已到店无法正常安排原预订房型入住时：
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （1）已确认订单出现无法安排入住等情况时，未及时联系甲方确认而自行采取其他措施的，视为放弃服务保障权利。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （2）不符合赔付服务保障范畴的几种情况：
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    a.入住日期、房型等事项变更，未经过甲方书面确认的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    b.甲方或酒店已提供不低于原预订标准的酒店房间客人不接受的,超出赔付标准的部分；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    c.因不可控因素（例如自然灾害，突发事件，政府征用）而造成的酒店无法入住。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    d.未实际入住或未提供真实有效的入住凭证，或入住凭证中关于入住人、城市、日期等信息与原订单不符。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    e.已过最晚付款时限未成功支付的预付订单。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    f.酒店已明确规定渠道限制，仍存在渠道来源不符的订单（例如来自淘宝、携程等的订单）
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （3）对于甲方与各合作伙伴（本协议相对方）已核实处理的赔付申请，甲方不再接受其他任何方或/和任何理由的赔付要求或/和赔付变更等。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （4）甲方所进行的赔偿金额不超过本协议第二条第3款对应的赔偿标准。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;16.4
                    旅游咨询服务、预订旅游、自由行、机票、高尔夫、签证、租车、会务、会展等视乙方具体业务情况进行安排。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;17.
                    乙方如有以下行为的，甲方有权中止提供服务或提前终止本协议，并要求乙方赔偿因此导致的全部损失：
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （1） 乙方提供的身份证明、企业登记资料等信息于实际不符或属虚假信息的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （2） 乙方未提供真实有效的联系方式，甲方无法与乙方取得联系，无法及时进行沟通或信息反馈的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （3） 将甲方提供的分销系统账号擅自转让或提供给第三方使用的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （4） 乙方恶意使用或违反诚实信用原则使用甲方分销系统的，或在交易中心已造成过多交易纠纷\投诉，或所进行的交易存在着过大交易风险，且经双方友好协商仍无法解决的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （5） 无正当理由不承认或拒绝经甲方分销系统确认的交易的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （6） 诋毁或损害甲方公司或甲方分销系统声誉的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （7） 通过IT技术手段对甲方分销系统发起恶意数据攻击的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （8） 乙方不遵循甲方提供的预付酒店产品价格线下操作原则，将甲方产品置于公开互联网渠道进行裸价销售（俗称裸卖）的，或乙方违反甲方对酒店产品的对外售价限制的。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18.
                    乙方可用分销系统为其客户提供酒店预订、查询等服务，同时负有保密义务；通过乙方的系统接入、发送图片的服务请求事项均视为乙方的行为，由乙方承担全部责任。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;19.
                    乙方可通过甲方网站或甲方书面指定的联系人员了解相关服务流程、资费标准等。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20.
                    乙方有义务对分销系统的管理员用户名及密码、员工用户名及密码做好保密工作。乙方在任何情况下不应将密码以任何方式提供给包括自称是甲方工作人员在内的任何人。因乙方用户名和密码保管及使用不当造成的损失及责任均由乙方全部承担。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;21.
                    除本协议约定或经甲方事先书面授权许可外，乙方不得以任何理由以甲方名义从事对外经营活动；否则，甲方因此而遭受的一切经济及名誉损失均由乙方承担。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;22.
                    未经甲方书面授权许可，乙方不得使用深圳春秋国旅、春秋旅游、壹同行及其关联商标/关键词/标识等。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;23.
                    甲方提供的所有预付酒店产品的价格需遵循线下操作原则，不适用于在公开互联网渠道进行裸价销售（俗称裸卖），并且乙方应遵守甲方对酒店产品的对外售价限制；对于乙方从公开互联网渠道获得的裸价销售订单或乙方违反甲方对酒店产品的对外售价限制获得的订单，甲方有权拒绝接纳或取消相关预定订单并停止数据服务，因此产生的全部责任与损失赔偿均由乙方承担。
                </p>
                <h2>第二条 财务结算</h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
                    甲方根据双方的业务往来情况给予乙方相应的业务结算挂账周期及信誉挂账额度。乙方享有的相应业务结算挂账周期及信誉挂账额度中有任意一项达到以下标准的，乙方应在规定期限内向甲方结清本次挂账账务。如乙方在规定期限内未结清本次挂账账务之前，甲方有权将不再给予乙方任何挂账。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    乙方享有的业务结算挂账周期为<span></span><span></span>
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    乙方享有的信誉挂账额度为<span></span><span></span>
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
                    以上甲乙双方约定好的业务结算帐期及额度，甲方有权根据双方的实际业务往来情况及结帐信誉作出相应调整。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
                    甲方根据乙方通过甲方壹同行分销系统预定日期的结算价格或/和线下预定日期的结算价格作为日后结算标准（依据）。乙方应在预定前清楚了解甲方线上、线下预定的《修改取消条款》和《预定须知》。当乙方操作人员在订单的“修改取消”和“预定须知”两处作出选择后即默认乙方已经清楚内容并确认接受，因此所产生的任何一笔费用，乙方均需按此执行结算向甲方进行支付。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.
                    甲方于月 日发对账单于乙方。乙方应于甲方发出对账单之日起 2 个工作日内与甲方进行账单确认回复，如乙方对此对账单未提出书面异议的，视为乙方已确认对账单金额。乙方应在对账确认日后 2
                    个工作日内将本次的挂账房费以现金或银行转账的方式支付给甲方。每次结算必须缴付甲方账单上要求的所有房费。乙方未结清本次所有相关费用的，不再享有挂账额度；在甲方核实确认乙方已完全结清甲方要求的所有费用后，经甲方书面许可，乙方方可再享有挂账额度。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.
                    乙方如出现以下任一情形的，甲方有权暂停或关闭许可乙方使用的壹同行分销系统账号，且乙方应每日按拖欠款项0.05%的比率向甲方支付滞纳金，同时甲方有权暂停乙方所有订单项下甲方向乙方交房的义务，直至乙方付清拖欠款项后方可恢复使用，因此产生的全部责任均由乙方承担；此外，在乙方付清拖欠款项之前，乙方支付给甲方的任何款项，均优先用于冲抵乙方的拖欠房款及滞纳金。所述任一情形如下：
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （1）未按约定时间结清账款的；
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    （2）当乙方欠款超过授信限额，未在规定的时间内向甲方结清欠款的。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.
                    乙方未按规定期限向甲方支付对账单上要求的所有费用的，每迟延一天，乙方除向甲方支付前述条款的滞纳金，还应向甲方支付对账单费用总额1%的违约金。甲方有权终止本协议或终止为乙方提供服务，由此造成的甲方的一切损失由乙方承担。甲方保留追究乙方法律责任的权利。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.
                    甲、乙双方合作期间，乙方预定团房的，有关团房结算不适用本协议上述结算条款及所涉方式，需一团一结算；相关结算时间按照团房订单上规定的付款结算时间进行。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.
                    甲、乙双方合作期间，本协议上述结算条款及所涉方式不适用于双方进行自由行、订车、订票、导游服务等业务事项；相关结算方式及结算时间等事宜，由双方视合作事项另行约定。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.
                    甲方向乙方提供酒店预订服务，如因财务损失、意外伤亡或不可抗力等情况造成乙方的一切损失和费用，甲方一概无须承担。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.
                    本协议双方并未约定任何固定期限，在结清相互之间的全部债权债务后，双方均有权提前7天以书面形式通知对方终止解除本协议。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11.
                    甲方接受乙方如下支付方式：银行转账、支票、现金、信用卡及银联卡；结算日期均以款项到账为准。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12.
                    任何形式结算手续费均由乙方承担。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13.
                    甲方公司账号：
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    开户名称：深圳春秋国际旅行社有限公司<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    开户银行：中国民生银行深圳罗湖支行<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    公司账号：1802 0141 7001 1617
                </p>

                <h2>第三条 协议续约与终止</h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
                    甲方对乙方提供基于《壹同行》分销系统的数据对接服务，服务期限至系统完成测试对接、正式上线后一年（具体正式上线，以双方友好协商沟通后，甲方正式发送开通邮件通知为准）。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
                    系统对接免收对接服务费。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
                    在双方合作都顺利，无特殊要求的情况下，本协议在上线满一年后自动续约一年。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.
                    当双方合作中途停止的情况下，如再需要重新合作，则需要双方沟通协商达成共识后，由甲方以邮件的形式发出重新开通上线通知（甲方保留要求乙方重新签定协议的权利）。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.
                    甲方终止服务时，以邮件形式通知到乙方。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.
                    在服务期限内，如乙方迟延支付甲方账款、违反本协议或甲乙双方之间其它生效法律文件，且未在甲方指定期限内纠正的，甲方有权停止乙方的数据对接服务、同时停止与乙方的线下服务。
                </p>
                <h2>第四条 其他事项</h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
                    乙方在签订本协议之前，有义务向甲方提供三证合一的工商注册营业执照副本原件及加盖乙方公章的复印件和《深圳春秋国旅业务结算授权申请表》原件，由甲方进行校对审核后保留复印件；若乙方为自然人的，应向甲方提供最新有效身份证原件及乙方签名的复印件，由甲方进行校对审核后保留相关复印件。甲方将对乙方所提供的资料及资信等情况进行尽职调查。本协议签订后若乙方三证合一的工商注册营业执照或身份证内容发生变更的，乙方应在发生相应变更的3日内向甲方提供变更后的资料。乙方未在以上规定时间内向甲方提供变更后资料的，本协议自动终止，由此造成的甲方的一切经济损失由乙方承担。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
                    乙方提供给甲方保留的相应复印件是本协议的附件。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
                    乙方向甲方提供变更后的资料，经甲方进行原件、复印件校对审核，在保留的复印件上签字确认后，方可追加为本协议的附件。
                </p>
                <h2>第五条 保密条款</h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
                    保密信息指一方在双方协商、谈判、协议签署及履行过程中获知的，另一方和其关联公司所拥有、持有或控制并采取保密措施的任何信息和资料。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
                    甲乙双方任一方将以保护其自身的保密信息且在任何情况下均不低于业界管理标准的合法谨慎程度来保护另一方披露的保密信息，避免其被第三方获取使用，亦不得以本协议之外的目的自行使用或允许第三人使用披露方的保密信息。未经披露方书面许可，任一方不得向第三方泄露本协议所称之保密信息。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
                    一方违反本条约定泄露另一方的保密信息的，均应向对方赔偿因此所造成的一切损失并承担相关责任。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.
                    本条约定的保密义务长期有效，不受本协议终止、解除的影响。
                </p>
                <h2>第六条 不可抗力</h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
                    不可抗力指本协议双方在订立协议时不能预见、对其发生和后果不能避免且不能克服的事件；国家法律、法规、政策变化，电信部门规定的交易规则及费率变化也被视为不可抗力。若由于不可抗力致使乙方未能全部或部分履行本协议的，不构成违约；本协议内受影响之条款可在因不可抗力导致不能履行之范围内中止履行。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
                    鉴于电子商务所具有之特殊性质，甲方对黑客攻击、网络病毒、电信部门技术调整及线路故障导致之影响、因政府管制而造成的暂时行关闭、电力系统故障或限制性供电等在内的任何影响网络正常经营之情形不承担责任。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
                    鉴于目前网络技术及国内、国际电子商务环境均尚未成熟，其电子商务立法以及信用体制还不完善，在这种情况下乙方作为用户在开展电子商务业务时存在一定的风险性，包括但不限于因电信网络传输故障导致交易数据遗失、不法分子利用网络盗用乙方用户名、密码进行交易等风险，进而给乙方造成损失的，乙方须独立承担因上述风险造成的损失及引发的相关责任。
                </p>
                <h2>第七条 知识产权</h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
                    甲方开发的、许可乙方使用的分销系统，其全部知识产权归甲方所有。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
                    双方同意在本协议生效日前业已存在的任何商标、版权、专利、商业秘密以及其他全部知识产权和所有权均属于本协议生效日前既已拥有该权利的一方所有。任何一方均不可凭借本协议而取得另一方所拥有的前述商标、版权、专利、商业秘密以及其他知识产权和所有权。
                </p>
                <h2>第八条 违约责任</h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
                    乙方违约，甲方有权终止为乙方提供本协议约定的服务，并要求乙方赔偿甲方的实际损失及主张实际损失所产生的费用。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
                    甲方违约，乙方有权要求甲方赔偿实际损失及主张实际损失所产生的费用。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
                    不可抗力原因导致违约，双方互不负赔偿责任，但因怠于履行通知义务所导致的对方损失一方不能免责。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.
                    主张实际损失所产生的费用包括但不限于：诉讼费、律师费、差旅费及其他有关费用。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.
                    任何一方没有行使其权利或没有就双方的违约行为采取任何行动，不应被视为是对该权利的放弃或对追究违约责任或义务的放弃。双方所有放弃的意思表示均应以书面形式做出并通知到对方。
                </p>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.
                    有关甲方向乙方提供线下服务事项，乙方未按照约定时限向甲方支付结算款项、违约赔偿或其他按照约定或法律规定应当由乙方支付给甲方的费用的，自违约之日起，每日按照应付未付清费用总额的
                    1%向甲方支付迟延履行金，并承担甲方为追讨上述费用或维护自身的其他权益而支出的包括但不限于律师费、差旅费、误工费、向诉讼担保公司支付的保费、公证认证费等费用。
                </p>
                <h2>第九条 适用法律与争议的解决</h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    除本协议另有规定外，甲乙双方就本协议或本协议之履行而产生的一切争议，均应先通过友好协商的方式解决。若协商不成，任何一方均可依法在本协议签订地人民法院提起诉讼。
                </p>
                <h2>第十条 </h2>
                <p class="agreements_text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    本协议一式两份，自双方签字盖章生效，具有同等法律效力。未尽事宜，双方可另行协商签补充协议。补充协议与本协议具同等法律效力。本协议附件与本协议具有同等效力。
                </p>

                <h2>以下为签署页，无正文</h2>
                <div class="yz_">
                    <div class="yz_l">
                        <p class="agreements_text">甲方（盖章）：深圳春秋国际旅行社有限公司</p>
                        <p class="agreements_text">甲方法定代表人：</p>
                        <p class="agreements_text">财务联系人：0755-82258150 黄小姐</p>
                        <p class="agreements_text">业务联系人：</p>
                        <p class="agreements_text">联系电话：0755-88866566 </p>
                        <p class="agreements_text">传真：0755-82396738 </p>
                        <p class="agreements_text">签订地址：深圳罗湖区深南东路中建大厦1111室 </p>
                        <p class="agreements_text">签字日期：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 年
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日</p>
                    </div>
                    <div class="yz_r">
                        <p class="agreements_text">乙方（盖章）：</p>
                        <p class="agreements_text">乙方法定代表人：</p>
                        <p class="agreements_text">财务联系人：</p>
                        <p class="agreements_text">业务联系人：</p>
                        <p class="agreements_text">联系电话：</p>
                        <p class="agreements_text">传真：</p>
                        <p class="agreements_text">地址：</p>
                        <p class="agreements_text">签字日期： &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 日</p>
                    </div>
                </div>
                <h2>附：深圳春秋国际旅行社有限公司存款帐户指引一份；业务结算授权申请表一份。</h2>
                <!-- 表格 -->
                <div>
                    <div style="font-size: 24px;text-align: center;width: 700px;line-height: 50px;">存款账户指引</div>
                    <table border="1" style="border: 1px solid #333;text-align: left;width: 700px;height: 350px;"
                        cellpadding="1" cellspacing="1">
                        <tr>
                            <th colspan="2">对公账户</th>
                        </tr>
                        <tr>
                            <td>开户名称</td>
                            <td>深圳春秋国际旅行社有限公司</td>
                        </tr>
                        <tr>
                            <td>开户银行</td>
                            <td>中国民生银行深圳罗湖支行</td>
                        </tr>
                        <tr>
                            <td>公司帐号</td>
                            <td>1802 0141 7001 1617</td>
                        </tr>
                        <tr>
                            <th colspan="2">对公账户</th>
                        </tr>
                        <tr>
                            <td>开户名称</td>
                            <td>深圳春秋国际旅行社有限公司</td>
                        </tr>
                        <tr>
                            <td>开户银行</td>
                            <td>光大银行深圳分行罗湖支行</td>
                        </tr>
                        <tr>
                            <td>公司帐号</td>
                            <td>3895 0188 0000 49971</td>
                        </tr>
                    </table>
                </div>
                <div style="padding-bottom: 20px;">
                    <div style="font-size: 24px;text-align: center;width: 700px;line-height: 50px;">深圳春秋国旅业务结算授权申请表
                    </div>
                    <table border="1" style="border: 1px solid #333;text-align: left;width: 700px;height: 500px;"
                        cellpadding="1" cellspacing="1">
                        <tr>
                            <td>公司名称</td>
                            <td colspan="5"></td>
                        </tr>
                        <tr>
                            <td>营业执照号码</td>
                            <td colspan="2" style="width: 120px;"></td>
                            <td>成立日期</td>
                            <td colspan="2" style="width: 120px;"></td>
                        </tr>
                        <tr>
                            <td>实际使用办公场所</td>
                            <td colspan="2"></td>
                            <td>营业期间（有效期）：</td>
                            <td colspan="2"></td>
                        </tr>
                        <tr>
                            <td>注册地址</td>
                            <td colspan="5"></td>
                        </tr>
                        <tr>
                            <td>业务负责人</td>
                            <td style="width: 120px;"></td>
                            <td>职衔</td>
                            <td style="width: 120px;"></td>
                            <td>电话</td>
                            <td style="width: 120px;"></td>
                        </tr>
                        <tr>
                            <td>业务负责人</td>
                            <td style="width: 120px;"></td>
                            <td>职衔</td>
                            <td style="width: 120px;"></td>
                            <td>电话</td>
                            <td style="width: 120px;"></td>
                        </tr>
                        <tr>
                            <td>业务负责人</td>
                            <td style="width: 120px;"></td>
                            <td>职衔</td>
                            <td style="width: 120px;"></td>
                            <td>电话</td>
                            <td style="width: 120px;"></td>
                        </tr>
                        <tr>
                            <td>公司性质</td>
                            <td colspan="5">
                                <span>国企 □</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>名企 □</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>私企 □</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>个人 □</span>
                            </td>
                        </tr>
                        <tr>
                            <td>申请挂账额度</td>
                            <td colspan="2"></td>
                            <td>申请结算周期</td>
                            <td colspan="2"></td>
                        </tr>
                        <tr>
                            <td colspan="6">线下订房单主要识别的模式：（请在后面打“√”，可多选；请随附）</td>
                        </tr>
                        <tr>
                            <td colspan="6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>（1）凭固定订单模板
                                    □</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>（2）凭预留印章 □</span>
                            </td>
                        </tr>

                    </table>
                </div>

            </div>  


        

        </div>
    </div> 
</template>

<script>

export default {
  name: 'CooperativeAgreement',
  data(){
    return{

    }
    
  },
  mounted() {

    document.title = this.$route.meta.title;
  }


}
</script>
<style scoped>
.apply_content {
    margin-top: 20px;
  padding-top: 20px;
}
.apply_content .apply_info {
  margin: 0 auto;
}
.apply_content .apply_info .apply_title {
  width: 1150px;
  padding: 0 20px;
  font-size: 18px;
  line-height: 60px;
  background: #fff;
  margin-bottom: 20px;
}
.apply_content .apply_info .agreements {
  padding: 0 50px;
  background: #fff;
}
.apply_content .apply_info .agreements h1 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 100px;
}
.apply_content .apply_info .agreements h2 {
  font-size: 16px;
  font-weight: bold;
  line-height: 50px;
}
.apply_content .apply_info .agreements p {
  line-height: 24px;
  margin-bottom: 14px;
}
.apply_content .apply_info .agreements p span {
  display: inline-block;
  width: 70px;
  border-bottom: 1px solid #666;
}
.apply_content .apply_info .agreements .agreements_text {
  font-size: 14px;
}
.apply_content .apply_info .agreements .yz_ {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.apply_content .apply_info .agreements .yz_ .yz_l,
.apply_content .apply_info .agreements .yz_ .yz_r {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
table th {
  padding-left: 10px;
}
table td {
  padding-left: 10px;
}

</style>
    