<template>

  <a-layout class="nd-layout_container nd-layout_large">

    <a-layout-sider width="200" theme="light"><leftMenu /></a-layout-sider>

    <a-layout-content :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '500px' }" >
            
      <div class="search-result-list">
        
        <a-table 
        class="ant-table-striped"
        :columns="columns" 
        :pagination="false" 
        :data-source="dataSource" 
        bordered 
        @resizeColumn="handleResizeColumn">
        </a-table>



        <div class="hotel-list-pagination">
          <a-pagination
            v-model:current="formData.page"

            :showQuickJumper="true"
            :showSizeChanger="false"
            :total="formData.count"
            @change="handlePageChange"
            :show-total="total => `共 ${total} 个账号`"
          />
        </div>


      </div>

    </a-layout-content>

  </a-layout>





</template>



<script>
import leftMenu from '@/components/leftMenu.vue';
//import router from '@/assets/router';
export default {
  name: 'SysNotice', 
  data(){
    return{

      formData:{
        page:1,
        pageSize:10,
        count:0,
      },

     columns:[
              {
                title: '编号',
                width: 70,
                dataIndex: 'index',
        
              },
              {
                title: '发布时间',
                width: 120,
                dataIndex: 'publishTime',               
              },
              {
                title: '标题',
                dataIndex: 'title',
              
              },

              {
                title: '内容',
                dataIndex: 'content',
               
              }
            ],
     dataSource:[]


    }
    
  },
  mounted() {
    document.title = this.$route.meta.title;
// 假设这是在组件的生命周期钩子或者某个事件处理器中

  },
  created() { 

    this.getNoticeList()
  },
  components: { 
    leftMenu
  },
  computed: {

  }, 


  methods: {

    async getNoticeList(page){

        this.searchBtnLoading = true;
        this.formData.page=page??this.formData.page  //点击搜索按钮需要重置第一页，
        var data ={         
          PageIndex:this.formData.page-1,
          PageSize: this.formData.pageSize,
        }
        try {   
        const response = await this.$axios.get('/notice',data);
        console.log(response.data)
        if (response.data.RetCode === "Success") {
            this.formData.count=response.data.count;
            this.dataSource=response.data.data.map((item,index) => {
              return {
                index: index+1,
                publishTime: item.CreateDateStr,
                title: item.NewsTitle,
                content: item.NewsContent
              };
            });
        }


      } catch (error) {

        this.$error({
                title: '啊哦~',
                content: '获取数据失败',
                okType: 'danger',
                okText: '请刷新重试',
                onOk() {                 
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);  
                },
        });

      }



    },

    //分页按钮
    handlePageChange(page) {
      this.formData.page=page
      this.getNoticeList()
      
    },
    handleResizeColumn(w, col){
      col.width = w;
    }
  }
}
</script>
<style scoped>

</style>
