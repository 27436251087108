<template>

  <div class="recommend_title text-green"> 境内特惠</div>
  <div class="discounts inlandHots" id="inlandHots">
    <a href="#" v-for="item in inlandHots" :key="item.HotelId">
      <div class="discounts_info">
        <a-image
          :width="385"
          :preview="false"
          :src="item.PhotoUrl"
          fallback="https://img.etxing.com/b2b/images/hotelDefault.svg"
        />
        <div class="hotels_name">
          <span>{{ item.HotelName }}</span>
          <span>￥{{ item.LowestPrice || 0 }}</span>
        </div>
      </div>
    </a>
  </div>

</template>
  
  <script>

  
  export default {
    name: 'hotSaleHotels',
    data() {
      return {
        inlandHots: []
      };
    },
    created() {
      this.fetchInlandHots();
    },
    methods: {
      async fetchInlandHots() {
       
        try {   
          const response = await this.$axios.get('/hotSale');
          this.inlandHots = response.data.HotHotels; // 根据 API 响应结构调整
          
        } catch (error) {
          console.error('获取数据失败:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
.recommend_title {
  font-size: 20px;
  line-height: 65px;
  margin-top: 10px;

}

.discounts {

  min-height: 530px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 40px;
}
.discounts a {
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
}
.discounts .discounts_info {
  position: relative;
  width: 385px;
  height: 260px;
  background: #ddd;
  overflow: hidden;
}
.discounts .discounts_info img {
  width: 420px;
  margin-top: -20px;
}
.discounts .discounts_info .hotels_name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
  position: absolute;
  bottom: 0px;
  width: 385px;
  font-size: 16px;
  line-height: 40px;
  color: #fff;
  background: #00000086;
}

  </style>
  