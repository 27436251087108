<template>

    <a-layout class="nd-layout_container nd-layout_large">
  
      <a-layout-sider width="200" theme="light"><leftMenu /></a-layout-sider>
  
      <a-layout-content :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '500px' }" v-if="memberData.MemberContacts.length > 0">
              
   

  
          
          <a-descriptions title="账号信息"  bordered>
            <template #extra><a-button type="text" @click="showEditModal">编辑/修改密码</a-button></template>            
            <a-descriptions-item label="登录账号" :span="3" style="width: 150px;">{{memberData.MemberContacts[0].LoginId}}</a-descriptions-item>
            <a-descriptions-item label="姓名" :span="3">{{memberData.MemberContacts[0].ContactName}}</a-descriptions-item>
            <a-descriptions-item label="邮箱" :span="3">{{memberData.MemberContacts[0].Email}}</a-descriptions-item>
            <a-descriptions-item label="手机号码" :span="3">{{memberData.MemberContacts[0].Tel}}</a-descriptions-item>
          </a-descriptions>
          

          <a-divider />

     
          <a-descriptions  title="企业信息" bordered>
            <a-descriptions-item label="客户名称" :span="2" >{{memberData.Member.Name}}</a-descriptions-item>
            <a-descriptions-item label="结算币种" >{{memberData.Member.TradingCurrency}}</a-descriptions-item>
            <a-descriptions-item label="联系人" >{{memberData.Member.ContactPerson}}</a-descriptions-item>
            <a-descriptions-item label="联系电话" >{{memberData.Member.Tel}}</a-descriptions-item>
            <a-descriptions-item label="邮箱" >{{memberData.Member.Email}}</a-descriptions-item>
            <a-descriptions-item label="地址" :span="3">{{memberData.Member.Address}}</a-descriptions-item>
            <a-descriptions-item label="客户经理" :span="3">{{memberData.Member.SaleManager}} / {{memberData.Member.SaleManagerTel}}</a-descriptions-item>
            <a-descriptions-item label="信用额度" >{{memberData.MemberCredit.Credit}}</a-descriptions-item>
            <a-descriptions-item label="信用余额" >{{memberData.MemberCredit.Credit-memberData.MemberCreditUsed}}</a-descriptions-item>
            <a-descriptions-item label="临时额度" >{{memberData.MemberTempCredit}}</a-descriptions-item>
            
          </a-descriptions>
    
      </a-layout-content>
  
    </a-layout>
  
      <a-modal v-model:open="editModaleOpen" title="编辑/修改密码" :confirm-loading="confirmLoading" @ok="handleOk">
        <a-form
          :model="formData"
          :label-col="{ span: 5 }"
          
        >
          <a-form-item
            name="LoginId"
            label="登录账号"
  
          >
            <a-input v-model:value="formData.LoginId" placeholder="请输入账号" :bordered="false" disabled />

            
          </a-form-item>

          <a-form-item
            name="ContactName"
            label="昵称"
        
            :rules="[{ required: true, message: '请输入用户名' }]"
          >
            <a-input v-model:value="formData.ContactName" placeholder="请输入账号" />
          </a-form-item>


          <a-form-item
            name="Email"
            label="邮箱"
        
            :rules="[{ required: true, message: '请输入用户名' }]"
          >
            <a-input v-model:value="formData.Email" placeholder="请输入账号" />
          </a-form-item>


          <a-form-item
            name="Tel"
            label="手机号码"


          >
            <a-input v-model:value="formData.Tel" placeholder="请输入账号" />
          </a-form-item>



          
          <a-form-item name="PasswordOld" label="登录原密码"  >
            <a-input-password v-model:value="formData.PasswordOld"  placeholder="如需修改请填写" />
          </a-form-item>
          <a-form-item name="Password" label="登录新密码"  >
            <a-input-password v-model:value="formData.Password"  placeholder="如需修改请填写" />
          </a-form-item>
          
          <a-form-item name="PayPasswordOld" label="支付原密码"  >
            <a-input-password v-model:value="formData.PayPasswordOld"  placeholder="如需修改请填写" />
          </a-form-item>
          <a-form-item name="PayPassword" label="支付新密码"  >
            <a-input-password v-model:value="formData.PayPassword"  placeholder="如需修改请填写" />
          </a-form-item>


          
          
        </a-form>
      </a-modal>

  </template>
  
  
  
  <script>
  import router from '@/assets/router';
  import leftMenu from '@/components/leftMenu.vue';
  import { message } from 'ant-design-vue';
  //import { Modal } from 'ant-design-vue';
  import Cookies from 'js-cookie';
  //import router from '@/assets/router';
  export default {
    name: 'OrderList', 
    data(){
      return{

        memberData:{
          MemberContacts:[],
          Member:{},
          MemberCredit:{}
        },
        editModaleOpen:false,
        confirmLoading:false,
        formData :{
          Operation: 1,
          Id: 0,
          LoginId: "",
          ContactName: "",
          Tel: "",
          Email: "",
          EContact: "",
          PasswordOld: "",
          Password: "",
          PayPasswordOld: "",
          PayPassword: ""
        },

  
  
      }
      
    },
    mounted() {
      document.title = this.$route.meta.title;
  // 假设这是在组件的生命周期钩子或者某个事件处理器中
  
    },
    created() { 
      this.fetchMemberInfo()
      //this.orderId=this.$route.query.OrderId;
      
    },
    components: { 
      leftMenu
    },
    computed: {
    
    }, 
  
  
    methods: {

          fetchMemberInfo(){
          

                var memberCacher=Cookies.get('etxing_'+Cookies.get('token'));
                //console.log(memberCacher)
                if(memberCacher!=undefined){
                  //console.log("缓存中")
                  this.memberData=JSON.parse(memberCacher);
                  //console.log(this.memberData)

                  return;
                }
                  

                this.$axios.get('/member')
                .then(response=>{
                  //console.log(response)
                const data=response.data;
                
                delete data.Company;

                Cookies.set('etxing_'+Cookies.get('token'), JSON.stringify(data), { expires: 1 }); // 1天过期
                this.memberData=data;
                // console.log(this.memberData)
                  
                })
                .catch(error => {
                // message.error(error.data.Message,1.5)
                  console.error("Error addMembers data:", error);
                  router.push('/') 
                });
           },
           showEditModal(){
            this.formData.Id=this.memberData.MemberContacts[0].Id
            this.formData.LoginId=this.memberData.MemberContacts[0].LoginId;
            this.formData.ContactName=this.memberData.MemberContacts[0].ContactName;
            this.formData.Tel=this.memberData.MemberContacts[0].Tel;
            this.formData.Email=this.memberData.MemberContacts[0].Email;           
            this.editModaleOpen=true;
           },
           async handleOk(){
           
            this.confirmLoading = true;
            try {

              if (this.formData.PasswordOld ==""&&this.formData.Password!="") {
                  message.error('登录原密码不能为空');
                  this.confirmLoading = false;
                  return;
              }
              if (this.formData.PayPasswordOld ==""&&this.formData.PayPassword!="") {
                  message.error('登录原密码不能为空');
                  this.confirmLoading = false;
                  return;
              }
              
                const response = await this.$axios.post('/memberContact', this.formData, {
                    responseType: 'json',
                    withCredentials: true,
                    timeout: 5000
                });
                console.log(response)
                if (response.data.RetCode == "Success") {
                  this.editModaleOpen=false;
                  Cookies.remove('etxing_'+Cookies.get('token')); // 1天过期
                  this.$success({
                    title: '恭喜您',
                    content: response.data.RetMessage,
                    okText: '确定',
                    onOk() {
                     window.location.reload(); 
                    },
                  });
                } else {
                    // 登录失败，显示错误信息
                    message.error( response.data.RetMessage || '操作失败，请重试');

                }
            } catch (error) {
                message.error( '操作失败，请重试');
                console.error('操作失败:', error);
            } finally {
                this.confirmLoading = false;
            }


            
           }
      
    }
  }
  </script>
  <style scoped>
.custom-label {
  width: 120px !important; /* 设置为你需要的宽度 */
}
  </style>
  