<template>
    <div class="nd-layout_container nd-layout_normal nd-layout_box register-container">
      <a-typography-title :level="4" style="text-align: center;">申请加盟</a-typography-title>
      <a-form
    ref="formRef"
    :model="formState"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-item label="公司名称" name="companyName">
      <a-input v-model:value="formState.companyName" />
    </a-form-item>
    <a-form-item label="公司性质" name="companyType">
      
      <a-radio-group v-model:value="formState.companyType" name="radioGroup" :options="companyTypeOptions">

      </a-radio-group>
      
    </a-form-item>
    <a-form-item label="所在地区" name="region">
      <a-cascader
        v-model:value="formState.region"
        :options="regionOptions"

        placeholder="Please select"
        change-on-select
      />
    </a-form-item>

    <a-form-item label="公司地址" name="address">
      <a-textarea v-model:value="formState.address" />
    </a-form-item>
    <a-form-item label="联系人" name="linker">
      <a-input v-model:value="formState.linker" />
    </a-form-item>
    <a-form-item label="手机号码" name="mobile">
      <a-input v-model:value="formState.mobile" />
    </a-form-item>
    <a-form-item label="联系电话" name="tel">
      <a-input v-model:value="formState.tel" />
    </a-form-item>
    <a-form-item label="联系邮箱" name="email">
      <a-input v-model:value="formState.email" />
    </a-form-item>

    <a-form-item label="公司简介" name="desc">
      <a-textarea v-model:value="formState.desc" />
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 13, offset: 5 }">
      <a-button  :disabled="loading"  type="primary" @click="onSubmit">提交申请</a-button>
      <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
      <a-button type="link" href="/">已有账号？去登录</a-button>
    </a-form-item>
  </a-form>

      
    </div>
  </template>
  
<script setup>
import { getCurrentInstance } from 'vue';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/assets/router';
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import addressData  from'@/assets/js/address.json';
const { proxy } = getCurrentInstance(); // 获取当前 Vue 实例的上下文
const route = useRoute(); 

    onMounted(() => {
      document.title =  route.meta.title;  // 设置页面标题
    });


const companyTypeOptions =  [
  {
    label: '旅行社',
    value: '1'
  },
  {
    label: '旅行社门店',
    value: '2'
  },
  {
    label: '港澳专线',
    value: '3'
  },
  {
    label: '泰国专线',
    value: '4'
  },
  {
    label: '巴厘岛专线',
    value: '5'
  },
  {
    label: '海岛专线',
    value: '7'
  },
  {
    label: '越南专线',
    value: '8'
  },
  {
    label: '商务公司',
    value: '9'
  },
  {
    label: '在线平台',
    value: '10'
  },
  {
    label: '新加坡专线',
    value: '12'
  },
  {
    label: '企业客户',
    value: '13'
  },
  {
    label: '其他',
    value: '6'
  },
];
const loading=ref(false);
const regionOptions =addressData;
const formRef = ref();
const labelCol = {
  span: 5,
};
const wrapperCol = {
  span: 13,
};
const formState = reactive({
  companyName: '',
  companyType:"1",
  region: ['1'],
  address: '',
  linker: '',
  mobile: '',
  tel: '',
  email: '',
  desc: '',
});
const rules = {
  companyName: [
    {
      required: true,
      message: '请输入公司名字',
      trigger: 'change',
    },
    {
      min: 2,
      max: 100,
      message: '不少于2个字',
      trigger: 'blur',
    },
  ],
  region: [
    {
      required: true,
      message: '请选择地区',
      trigger: 'change',
    },
  ],
  linker: [
    {
      required: true,
      message: '请输入联系人',
      trigger: 'change',
    },
  ],
  mobile: [
    {
      required: true,
      message: '请填写手机号',
      trigger: 'change',
    }
  ],
  email: [
    {
      required: true,
      message: '请填写邮箱',
      trigger: 'change',
    },
    {
      type: 'email',
      message: '邮箱不正确',
      trigger: 'blur',
    },
  ],

};
const onSubmit = () => {
    formRef.value
    .validate()
    .then(() => { 
      console.log('values', formState);
      loading.value=true;
      proxy.$axios.post('/addMembers',{
          "ApplyType": "b",
          "UserName": formState.companyName,
          "Country": parseInt(formState.region[0]),
          "Province":parseInt(formState.region[1]??0),
          "City":  parseInt(formState.region[2]??0),
          "District": 0,
          "CompanyType": parseInt( formState.companyType),
          "Address": formState.companyType,
          "ContactPerson": formState.linker,
          "Mobile": formState.mobile,
          "Email": formState.email,
          "CompanyDesc": formState.desc,
          "Tel": formState.tel

       })
      .then(response =>{
        loading.value=false;
        console.log(response.data)
        if(response.data.RetCode=="Fail")  {
            message.error(response.data.Message,1.5)
          
          }else{

         
            proxy.$success({
              title: '恭喜您',
              content: response.data.Message,
              okText: '确定',
              onOk() {
                router.push("/")
 
              },
            });
            //console.log(this.keywordValue)
          }

      })
      .catch(error => {
       // message.error(error.data.Message,1.5)
        console.error("Error addMembers data:", error);
      });


      
     
    })
    .catch(error => {
      console.log('error', error);
    });
};
const resetForm = () => {
  formRef.value.resetFields();
};
</script>
  <style>
  .register-container {

    margin-top: 20px !important;
  }
  </style>
  