<template>

  <a-layout class="nd-layout_container nd-layout_large">

    <a-layout-sider width="200" theme="light"><leftMenu /></a-layout-sider>

    <a-layout-content :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '500px' }" >
            
      <a-form>
        <a-row :gutter="24" >
          <a-col :span="6">
            <a-form-item label="用户状态">
              <a-select v-model:value="formData.status" placeholder="全部">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="1">正常</a-select-option>
                <a-select-option value="0">禁止</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="关键字">
              <a-input v-model:value="formData.keyword" />
            </a-form-item>
          </a-col>
          <a-col :span="12" >
            <a-space :size="10">
              <a-button type="primary" :disabled="searchBtnLoading" :loading="searchBtnLoading" @click="getAccountList(1)">搜索</a-button>
              <a-button  :disabled="searchBtnLoading" @click="handleEdit()">添加子账号</a-button>
    
            </a-space>

            
          </a-col>
  

        </a-row>


      </a-form>
      <div class="search-result-list">
        
        <a-table 
        class="ant-table-striped"
        :columns="columns" 
        :pagination="false" 
        :data-source="dataSource" 
        bordered 
        @resizeColumn="handleResizeColumn">
          <template #bodyCell="{ column, record }">

            <template v-if="column.dataIndex === 'status'"> 
              <a-badge :status="record.status==1?'success':'error'" :text="record.status==1?'正常':'禁止'" />               
            </template>
            <template v-if="column.dataIndex === 'operation'">    
              <a-button type="text" @click="handleEdit(record)" >修改</a-button>
              <a-button type="text" @click="handleDelete(record)">删除</a-button>
            </template>


          </template>
        </a-table>



        <div class="hotel-list-pagination">
          <a-pagination
            v-model:current="formData.page"

            :showQuickJumper="true"
            :showSizeChanger="false"
            :total="formData.count"
            @change="handlePageChange"
            :show-total="total => `共 ${total} 个账号`"
          />
        </div>


      </div>

    </a-layout-content>

  </a-layout>


  <a-modal v-model:open="editModaleOpen" :title="formUserData.Id>0?'编辑/修改密码':'新增子账号'" :confirm-loading="confirmLoading" @ok="handleOk">
        <a-form
          :model="formUserData"
          :label-col="{ span: 6 }"
          
        >
          <a-form-item
            name="LoginId"
            label="登录账号"
  
          >
          <a-input v-if="formUserData.Id>0" v-model:value="formUserData.LoginId" placeholder="请输入账号" :bordered="false"  disabled />
          <a-input v-else v-model:value="formUserData.LoginId" placeholder="请输入账号"   />

            
          </a-form-item>

          <a-form-item
            name="ContactName"
            label="昵称"
        
            :rules="[{ required: true, message: '请输入姓名' }]"
          >
            <a-input v-model:value="formUserData.ContactName" placeholder="请输入姓名" />
          </a-form-item>


          <a-form-item
            name="Email"
            label="邮箱"
        
            :rules="[{ required: true,  type: 'email', message: '请输入邮箱' }]"
          >
            <a-input v-model:value="formUserData.Email" placeholder="请输入账号" />
          </a-form-item>


          <a-form-item
            name="Tel"
            label="手机号码"


          >
            <a-input v-model:value="formUserData.Tel" placeholder="请输入账号" />
          </a-form-item>


          <a-divider orientation="left">安全设置</a-divider>
          
          <a-form-item v-if="formUserData.Id>0" name="PasswordOld" label="登录原密码"  >
            <a-input-password v-model:value="formUserData.PasswordOld"  placeholder="如需修改请填写" />
          </a-form-item>
          <a-form-item name="Password" label="登录新密码"  >
            <a-input-password v-model:value="formUserData.Password"  placeholder="如需修改请填写" />
          </a-form-item>
         
          <a-form-item name="PasswordConfirm" label="确认登录新密码"  >
            <a-input-password v-model:value="formUserData.PasswordConfirm"  placeholder="如需修改请填写" />
          </a-form-item>

          <a-form-item v-if="formUserData.Id>0" name="PayPasswordOld" label="支付原密码"  >
            <a-input-password v-model:value="formUserData.PayPasswordOld"  placeholder="如需修改请填写" />
          </a-form-item>
          <a-form-item name="PayPassword" label="支付新密码"  >
            <a-input-password v-model:value="formUserData.PayPassword"  placeholder="如需修改请填写" />
          </a-form-item>
          <a-form-item name="PayPasswordConfirm" label="确认支付新密码"  >
            <a-input-password v-model:value="formUserData.PayPasswordConfirm"  placeholder="如需修改请填写" />
          </a-form-item>
         
          <a-form-item label="登录状态">
            <a-switch v-model:checked="statusChecked" checked-children="正常" un-checked-children="禁止"/>
          </a-form-item>
                
          
        </a-form>
      </a-modal>



</template>



<script>
import leftMenu from '@/components/leftMenu.vue';
import dayjs from 'dayjs';
import { extractTimestamp } from '@/assets/js/utils.js';
import { message } from 'ant-design-vue';
//import router from '@/assets/router';
export default {
  name: 'OrderList', 
  data(){
    return{
      searchBtnLoading:false,
      editModaleOpen:false,
        confirmLoading:false,
        formUserData :{
          Operation: 1,
          Id: 0,
          LoginId: "",
          ContactName: "",
          Tel: "",
          Email: "",
          EContact: "",
          PasswordOld: "",
          Password: "",
          PasswordConfirm: "",
          PayPasswordOld: "",
          PayPassword: "",
          PayPasswordConfirm: "",
          Status:1
        },
        

      formData:{
        keyword:"",
        status:'',
        Operation:3,
        page:1,
        pageSize:10,
        count:0,
      },

     columns:[
              {
                title: '登录账户名',
                dataIndex: 'loginId',
        
              },
              {
                title: '姓名',
                dataIndex: 'contactName',               
              },
              {
                title: '电子邮件',
                dataIndex: 'email',
              
              },

              {
                title: '联系电话',
                dataIndex: 'mobile',
               
              },

              {
                title: '添加时间',
                dataIndex: 'createTime',
                
              },
              {
                title: '状态',
                dataIndex: 'status',
                
              },
              
              {
                title: '操作',
                dataIndex: 'operation',
              },
            ],
     dataSource:[]


    }
    
  },
  mounted() {
    document.title = this.$route.meta.title;
// 假设这是在组件的生命周期钩子或者某个事件处理器中

  },
  created() { 

    this.getAccountList()
  },
  components: { 
    leftMenu
  },
  computed: {
    statusChecked: {
            get() {
                return this.formUserData.Status === 1;
            },
            set(value) {
                this.formUserData.Status = value ? 1 : 0;
            }
        }
  }, 


  methods: {

    async getAccountList(page){

        this.searchBtnLoading = true;
        this.formData.page=page??this.formData.page  //点击搜索按钮需要重置第一页，
        var data ={         
          Operation: 3,
          ContactName: this.formData.keyword,
          Status: this.formData.status,
          PageIndex:this.formData.page-1,
          PageSize: this.formData.pageSize,
        }
        try {   
        const response = await this.$axios.post('/memberContact',data);
        console.log(response.data)
        if (response.data.RetCode === "Success") {
            this.formData.count=response.data.count;
            this.dataSource=response.data.data.map((item) => {
              return {
                id: item.Id,
                loginId: item.LoginId,
                contactName: item.ContactName,
                email: item.Email,
                mobile: item.Tel,
                createTime: dayjs(extractTimestamp(item.CreateDate)).format('YYYY-MM-DD HH:mm:ss'),
                status:item.Status
              };
            });
        }


      } catch (error) {

        this.$error({
                title: '啊哦~',
                content: '获取数据失败',
                okType: 'danger',
                okText: '请刷新重试',
                onOk() {                 
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);  
                },
        });

      }finally {
                this.searchBtnLoading = false;
      }



    },

    //分页按钮
    handlePageChange(page) {
      this.formData.page=page
      this.getAccountList()
      
    },
    handleDelete(record){

      this.formUserData.Operation=4
      this.formUserData.Id=parseInt(record.id)
      this.$confirm({
        title: '您确定删除该用户吗?',
        content: '删除后无法恢复',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          try {
            const response = await this.$axios.post('/memberContact',this.formUserData);
            if (response.data.RetCode === "Success") {
              message.success('删除成功');
              this.dataSource=this.dataSource.filter(item => item.id !== record.id);
              this.formData.count--;
            }
          }catch (error) {
            message.error( '操作失败，请重试');
            console.error('操作失败:', error);
          } finally {
            this.confirmLoading = false;
          }
        }
      })
    },
    handleEdit(record){
      console.log(record)
      if(record)
      {
        this.formUserData.Operation=1
        this.formUserData.Id=parseInt(record.id)
        this.formUserData.LoginId=record.loginId;
        this.formUserData.ContactName=record.contactName;
        this.formUserData.Tel=record.mobile;
        this.formUserData.Email=record.email;     
        this.formUserData.Status=parseInt(record.status)  
      }
      else
      {
        this.formUserData.Operation=0
        this.formUserData.Id=0
        this.formUserData.LoginId="";
        this.formUserData.ContactName="";
        this.formUserData.Tel="";
        this.formUserData.Email="";   

      }

      this.editModaleOpen=true;
    },

    async handleOk(){
      
      this.confirmLoading = true;
      try {
          if( this.formUserData.Id>0){

            if (this.formUserData.PasswordOld ==""&&this.formUserData.Password!="") {
                message.error('登录原密码不能为空');
                this.confirmLoading = false;
                return;
            }
            else if (this.formUserData.Password !== this.formUserData.PasswordConfirm) {
                message.error('新登录密码两次输入不一致');
                this.confirmLoading = false;
                return;
            }
            
            if (this.formUserData.PayPasswordOld ==""&&this.formUserData.PayPassword!="") {
                message.error('支付原密码不能为空');
                this.confirmLoading = false;
                return;
            }
            else if (this.formUserData.PayPassword !== this.formUserData.PayPasswordConfirm) {
                message.error('新支付密码两次输入不一致');
                this.confirmLoading = false;
                return;
            }
            
          }

          if( this.formUserData.Id===0){

              if (this.formUserData.Password=="") {
                message.error('登录密码不能为空');
                this.confirmLoading = false;
                return;
              }
              else if (this.formUserData.Password !== this.formUserData.PasswordConfirm) {
                  message.error('登录密码两次输入不一致');
                  this.confirmLoading = false;
                  return;
              }

              if (this.formUserData.PayPassword=="") {
                  message.error('支付密码不能为空');
                  this.confirmLoading = false;
                  return;
              }
              else if (this.formUserData.PayPassword !== this.formUserData.PayPasswordConfirm) {
                  message.error('支付密码两次输入不一致');
                  this.confirmLoading = false;
                  return;
              }

          }



          console.log(this.formUserData)
          const response = await this.$axios.post('/memberContact', this.formUserData, {
              responseType: 'json',
              withCredentials: true,
              timeout: 5000
          });
          console.log(response)
          if (response.data.RetCode == "Success") {
            this.editModaleOpen=false;
          
            this.$success({
              title: '恭喜您',
              content: response.data.RetMessage,
              okText: '确定',
              onOk() {
              window.location.reload(); 
              },
            });
          } else {
              // 登录失败，显示错误信息
              message.error( response.data.RetMessage || '操作失败，请重试');

          }
      } catch (error) {
          message.error( '操作失败，请重试');
          console.error('操作失败:', error);
      } finally {
          this.confirmLoading = false;
      }


      
    },

    handleResizeColumn(w, col){
      col.width = w;
    }
  }
}
</script>
<style scoped>

</style>
