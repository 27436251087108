// utils.js

// 获取字符串的字节长度
export function getByteLength(str) {
    let byteLength = 0;
    for (let char of str) {
        const codePoint = char.codePointAt(0);
        if (codePoint <= 0x7F) {
            byteLength += 1;
        } else if (codePoint <= 0x7FF) {
            byteLength += 2;
        } else if (codePoint <= 0xFFFF) {
            byteLength += 3;
        } else {
            byteLength += 4;
        }
    }
    return byteLength;
}


export function arrayChanged(newArr, oldArr) {
    if (newArr.length !== oldArr.length) {
      return true;
    }
    for (let i = 0; i < newArr.length; i++) {
      if (JSON.stringify(newArr[i]) !== JSON.stringify(oldArr[i])) {
        return true;
      }
    }
    return false;
}


export function extractTimestamp(dateString) {
    // 使用正则表达式匹配时间戳
    const match = dateString.match(/\d+/);
  
    if (match) {
      // 转换为数字
      return parseInt(match[0], 10);
    } else {
      throw new Error('Invalid date string format');
    }
  }
  