<template>
  <div class="login_box">
      <div class="login_title">春秋国旅同业分销登陆</div>



      <a-form
        :model="formState"
        name="normal_login"
      
        @finish="onFinish"       
      >
        <a-form-item
          name="username"
          
          :rules="[{ required: true, message: '请输入用户名' }]"
        >
          <a-input v-model:value="formState.username" placeholder="请输入账号">
            <template #prefix>
              <UserOutlined  />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item
          name="password"
          :rules="[{ required: true, message: '请输入密码' }]"
        >
          <a-input-password v-model:value="formState.password"  placeholder="请输入密码" size="middle" autocomplete="off">
            <template #prefix>
              <LockOutlined />
            </template>
          </a-input-password>
        </a-form-item>

        <a-form-item>
          <a-button :disabled="loginLoading" type="primary"  :loading="loginLoading" html-type="submit" size="large"  shape="round" >
            登录
          </a-button>
        </a-form-item>
      </a-form>

      <div class="Quick_entry">
          <a href="forget.html">忘记密码</a><a href="/reg">免费注册</a>
      </div>
  </div>
</template>

<script>
import { UserOutlined, LockOutlined} from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import Cookies from 'js-cookie';
export default {
  name: 'LoginForm',
  data() {
      return {
        formState :{
            username: '',
            password: ''
          },
          hasLogin:false,
          memberData:{},
          loginLoading: false
      };
  },
  components:{
    UserOutlined,
    LockOutlined
  },
  mounted() {
    document.title = this.$route.meta.title;
    if(Cookies.get('token')){
      this.$router.push({ path: '/hotels' });
    }
  },
  methods: {
    async onFinish(){
     

      this.loginLoading = true;
          try {
              const response = await this.$axios.post('/Login/B2BPC', {
                  username: this.formState.username,
                  password: this.formState.password
              }, {
                  responseType: 'json',
                  withCredentials: true,
                  timeout: 5000
              });
              //console.log(response)
              if (response.data.RetCode == "Success") {
                //console.log( response.data.SessionId)
                // 存储token到cookie中
                 Cookies.set('token', response.data.SessionId, { expires: 1 }); // 1天过期
                  // 登录成功，执行相应操作
                 // alert('登录成功');
                  // 例如，重定向到酒店搜索页面
                   this.$router.push({ path: '/hotels' });
              } else {
                  // 登录失败，显示错误信息
                  message.error( response.data.RetMessage || '登录失败，请重试');

              }
          } catch (error) {
              message.error( '登录请求失败，请稍后重试');
              console.error('登录请求失败:', error);
          } finally {
              this.loginLoading = false;
          }




    }
  }
};
</script>

<style scoped>

.ant-form-horizontal .ant-form-item .ant-input-affix-wrapper{
  line-height: 2.2 !important;
}

.ant-form-horizontal  .ant-btn-primary {
  width: 100%;
}

.login_box {
    position: absolute;
    top: -420px;
    right: 0;
    z-index: 99;
    width: 400px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
  }
  .login_box .login_title {
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    background: #fff;
    margin: 14px 0;
  }
  .login_box form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }




  .login_box .Quick_entry {
    height: 50px;
    line-height: 50px;
    width: 300px;
    text-align: right;
    margin: 0 auto;
    font-size: 14px;
  }

  .login_box .Quick_entry a:hover{text-decoration: underline}
  .login_box .Quick_entry a:nth-child(1) {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #eee;
  }
</style>
