<template>
  

      
      <section class="hotel_index_banner">


        <a-carousel autoplay   :style="{ backgroundColor: `#FFF`,height:`500px` }"  >
          <div :key="index" :id="index"  v-for="(item, index) in  bannerCarousels.datas">      
            
            <div class="carousel_img" 
            :style="{ backgroundImage: `url(${item.NewsPhoto})` }"></div>
          </div>
        </a-carousel>


      </section>


      <section class="hotel_search_section">
        
  
        <hotelSearchBox />

      </section>

      <!--特惠推荐-->
      <section class="hotel_promotion_section">
        
        <h1 class="promotion_title">今日特惠</h1>
        <div class="carousel_content">
          <div class="promotion_carousel">

            <a-carousel  dotPosition="top" dotsClass="slick-dotsCity">
              <template #customPaging="props">
               <span>{{getCity(props.i)}}</span>
              </template>

              <div v-for="(data, index) in promotionCarousels.datas"  :id="index" :key="index">

                <a-row space="10">
                  <a-col :span="8" v-for="hotel in data.Promotions" :key="hotel.HotelId">                  
                    <a :href="`/hotel/detail?hotelId=`+hotel.HotelId" class="promotion_city_hotels" target="_blank" title="吉林">            
                        <div class="today_img">
                            <span class="hotelPrice">￥{{ hotel.LowestPrice }}</span>
                            <img :src="hotel.PhotoUrl" alt="吉林松花湖瞻云SALOMON酒店">
                        </div>
                        <div class="today_text">
                            <span class="today_hotelName">{{ hotel.HotelName }}</span>
                            <span class="today_btn">立即预定</span>
                        </div>                    
                    </a>
                  </a-col>

                </a-row>

              </div>

            </a-carousel>

          </div>
        </div>
      </section>

      <!--热卖推荐-->
      <section class="hotel_hotsale_section">

        <a-tabs v-model="hotSaleHotels.hkmo.name" class="hot_house" >
          <a-tab-pane tab="港澳热卖">          
            <a-row  :gutter="16">
              <a-col :span="6" v-for="data in hotSaleHotels.hkmo.datas" :key="data.HotelId">      
                <a :href="`/hotel/detail?hotelId=`+data.HotelId" class="hot_house_piece">
                  
                  <a-card hoverable>
                    <template #cover>
                      <a-image                  
                        :preview="false"
                        :alt="data.HotelName"
                        :src="data.PhotoUrl"
                        fallback="https://img.etxing.com/b2b/images/hotelDefault.svg"
                      />
                    </template>
                    <a-card-meta :title="data.HotelName">
                      <template #description>
                        
                        <div class="house_info">
                     
                          <a-rate :value="data.Star" disabled style="font-size: 12px" ></a-rate>                    
                          <p><span class="hot_price">￥{{ data.LowestPrice }}</span><span class="hot_site">{{ data.HotelArea }}</span></p>
                        </div>


                      </template>
                    </a-card-meta>
                  </a-card>
                </a>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>


        <a-tabs v-model="hotSaleHotels.inbound.name" class="hot_house" >
          <a-tab-pane tab="国内热卖">          
            <a-row  :gutter="16">
              <a-col :span="6" v-for="data in hotSaleHotels.inbound.datas" :key="data.HotelId">      
                <a :href="`/hotel/detail?hotelId=`+data.HotelId" class="hot_house_piece">
                  
                  <a-card hoverable>
                    <template #cover>
                      <a-image                  
                        :preview="false"
                        :alt="data.HotelName"
                        :src="data.PhotoUrl"
                        fallback="https://img.etxing.com/b2b/images/hotelDefault.svg"
                      />
                    </template>
                    <a-card-meta :title="data.HotelName">
                      <template #description>
                        
                        <div class="house_info">
                     
                          <a-rate :value="data.Star" disabled style="font-size: 12px" ></a-rate>                    
                          <p><span class="hot_price">￥{{ data.LowestPrice }}</span><span class="hot_site">{{ data.HotelArea }}</span></p>
                        </div>


                      </template>
                    </a-card-meta>
                  </a-card>
                </a>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>

      </section>


</template>

<script>
import '@/assets/styles/hotel.css'
import hotelSearchBox from '@/components/hotelSearchBox.vue';
export default {
  name: 'HotelHome', 
  data(){
    return{
      bannerCarousels:{
        active:0,
        datas:[]
      },
      promotionCarousels:{
        active:0,
        datas:[]
      },
     hotSaleHotels:{
      inbound:{
        name:"inbound",
        datas:[]
      },
      hkmo:{
        name:"hkmo",
        datas:[]
      }
     }

    }
    
  },
  mounted() {

    this.fetchCarousel();
    this.fetchHotSale();
    document.title = this.$route.meta.title;
  },
  components: {
    hotelSearchBox
  },
  methods: {
    getCity(i){
      return this.promotionCarousels.datas[i].City;
    },

    fetchCarousel(){
      this.$axios.post('/carousel')
      .then(response =>{      
        if (response.data.RetCode == "Success") {
          this.bannerCarousels.datas=response.data.Banners.filter(banner => banner.BannerType === "top")
          this.bannerCarousels.active=0;         
        } else {
          console.log(response.data)
        }

      });
    },
    async fetchHotSale() {       
       try {   
         const response = await this.$axios.get('/hotSale');
         this.promotionCarousels.datas = response.data.CityPromotions; // 根据 API 响应结构调整
         this.hotSaleHotels.inbound.datas=response.data.HotHotels.filter(h=>h.HotelArea!='香港'&&h.HotelArea!='澳门').slice(0,8)
         this.hotSaleHotels.hkmo.datas=response.data.HotHotels.filter(h=>h.HotelArea=='香港'||h.HotelArea=='澳门').slice(0,8)
       } catch (error) {
         console.error('获取数据失败:', error);
       }
     },
     
    handleSelect(city) {
      console.log('Selected city:', city);
      // 处理选中的城市逻辑
    }
  }
}
</script>
<style scoped>
.search-box {
position: absolute;
top: -220px;
right: 0;
z-index: 99;
}
</style>
