<template>
  <a-dropdown trigger="['click']" :open="isOpen"  @openChange="handleoOpenChange">
    <a-button class="ant-dropdown-link"  @click.prevent="toggleOccupancySelect">
      {{options}}
      <component :is="iconType" />
    </a-button>
    <template v-slot:overlay>
      <a-menu>
        <a-menu-item key="0">
                <div style="width: 250px;" @click.stop >
                  <a-row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
                    <a-col>房间数</a-col>
                    <a-col>
                    
                      <a-input-group compact > 
                        <a-button @click="numberPlusMinus('rooms',-1)" :disabled="roomsDisable">
                          <template #icon> <MinusOutlined /></template>
                        </a-button>
                        <a-input
                          v-model:value="occupancy.rooms" 
                          style="width: 40px; text-align: center;" 
                        />
                        <a-button @click="numberPlusMinus('rooms',1)">
                          <template #icon><PlusOutlined /></template>
                        </a-button>
                      </a-input-group>
                    </a-col>
                  </a-row>

                  <a-row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
                    <a-col><a-typography-text>成人<a-typography-text type="warning"  style="font-size: 12px;">(18岁及以上)</a-typography-text></a-typography-text></a-col>
                    <a-col>
                    
    
                    <a-input-group compact>
                      <a-button @click="numberPlusMinus('adults',-1)"><template #icon> <MinusOutlined /> </template></a-button>
                      <a-input 
                        v-model:value="occupancy.adults" 
                        style="width: 40px; text-align: center;" 
                      />
                      <a-button @click="numberPlusMinus('adults',1)"><template #icon><PlusOutlined /></template></a-button>
                    </a-input-group>

                  </a-col>
                  </a-row>


                  <a-row type="flex" justify="space-between" align="middle">
                    <a-col><a-typography-text>儿童<a-typography-text type="warning" style="font-size: 12px;">(0-17岁)</a-typography-text></a-typography-text></a-col>
                    <a-col>
                    
   
 
                    <a-input-group compact>
                      <a-button @click="numberPlusMinus('children',-1)"><template #icon> <MinusOutlined /> </template></a-button>
                      <a-input 
                        v-model:value="occupancy.children" 
                        style="width: 40px; text-align: center;"                      
                      />
                      <a-button @click="numberPlusMinus('children',1)"><template #icon><PlusOutlined /></template></a-button>
                    </a-input-group>



                  </a-col>
                  </a-row>
                  <div v-if="occupancy.children > 0">
                    <a-divider style="margin: 8px 0;" dashed />
                    <label>儿童年龄</label>
                    <a-row :gutter="[4,4]">
                      <a-col :span="8"  v-for="(age,index) in occupancy.childrenAges" :key="index"  @click.stop>
                        <a-select
                          v-model:value="occupancy.childrenAges[index]"
                          size="small"
                          style="width: 80px;"
                          :options="ageOptions"
                          @change="(value) => handleChildAgeChange(value, index)"
                        ></a-select>
                      </a-col>
                    </a-row>
                  </div>

                  <a-typography-text v-show="tipsShow" type="warning" style="font-size: 12px; line-height: 20px" >注意：入住人数为总人数，非每间人数</a-typography-text>

                  
                  
                </div>
        </a-menu-item>
        </a-menu>

    </template>
  </a-dropdown>
</template>

<script>
   import { DownOutlined,UpOutlined,PlusOutlined,MinusOutlined } from '@ant-design/icons-vue';
export default {
  props: {
    propsOccupancy: {
        type: Object,
        default:()=>{
        return {
          rooms: 1,
          adults: 2,
          children: 0,
          childrenAges: []
        };
      }
      },
    propsOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      iconType: 'DownOutlined',
      occupancy:{
        rooms:1,
        adults:2,
        children:0,
        childrenAges:[]
      },
      options: "",
      dropdownOpen: true,
      tipsShow:false,
      roomsDisable:false,
      ageOptions: Array.from({ length: 18 }, (_, i) => ({
        value: i ,
        label: i>0?`${i}岁`:`<${i+1}岁`
      }))

    };
  },
  components:{
    DownOutlined ,
    UpOutlined,
    PlusOutlined,
    MinusOutlined
  },
  computed: {
    
  },

  created() {
    
    //console.log(this.propsOpen)
    this.isOpen=this.propsOpen
    if(this.propsOccupancy){
      this.occupancy=this.propsOccupancy
     // this.updateOptions()
    }
      
   ///console.log(this.occupancy.childrenAges)
  },
  methods: {

    numberPlusMinus(type,delta) {


      switch(type){
        case 'rooms':
          this.occupancy.rooms = this.occupancy.rooms + delta < 1 ? 1 : parseInt(this.occupancy.rooms) + delta;
          break;
        case 'adults':
          this.occupancy.adults = this.occupancy.adults + delta < 0 ? 0 : parseInt(this.occupancy.adults) + delta;
          break;
        case 'children':
          this.occupancy.children = this.occupancy.children + delta < 0 ? 0 : parseInt(this.occupancy.children) + delta;
          if(this.occupancy.children==0)
            this.occupancy.childrenAges= [];
          else{
            if(delta==1)   //增加
              this.occupancy.childrenAges.push(0)
            if(delta==-1)   //减少
              this.occupancy.childrenAges.splice(this.occupancy.childrenAges.length-1,1)
          }
          // console.log(this.childrenAges)
          break;
      }
      //console.log(this.occupancy)
      //成人人数少于房间数的时候提醒
      if(this.occupancy.adults<this.occupancy.rooms)
        this.tipsShow=true;
      else  
        this.tipsShow=false

      this.updateOptions();
    },

    handleChildAgeChange(value,index){
      this.occupancy.childrenAges[index]=value ;
    },
    updateOptions() {
      const option = `${this.occupancy.rooms}间, ${this.occupancy.adults}成人 ${this.occupancy.children}儿童`;
      this.options = option;

      this.$emit('occupancySelect',this.occupancy); // 传递选择值给父组件

    },
    handleoOpenChange(open) {
      this.$emit('occupancyOpen',open); // 传递选择值给父组件
      
      if(open){
        this.isOpen=true
        this.iconType = 'UpOutlined';

      }
    
      else {
        this.isOpen=false
        this.iconType = 'DownOutlined' ;
        this.$emit('searchHotelList');
      }
        
    },
    toggleOccupancySelect() {
      this.isOpen = !this.isOpen;
      if(this.isOpen) this.iconType = 'UpOutlined' 
      else   this.iconType = 'DownOutlined'
    },

  },
  watch: {




  },
  mounted() {
    this.updateOptions()
    //console.log(this.propsOpen)

  }
}
</script>

<style scoped>
/* 添加你的样式 */
</style>
