<template>



  <div class="nd-layout_container nd-layout_normal">
    <a-steps
      type="navigation"
      :current=2
      size="small"
      :items="[
        {
          title: '填写订单',
          status: 'finish',

        },
        {
          title: '选择支付方式',
          status: 'finish',
        },
        {
          title: '完成',
          status: 'finish',

        },
      ]"
    ></a-steps>  




    <div class="nd-panel-body">

      
      <a-result
        status="success"
        title="支付成功"
      >
        <template #subTitle>
          <span>订单号：<a-typography-text :copyable="true">{{orderId}}</a-typography-text> 您可以记下订单号,稍后自行查询！</span>
        </template>
        <template #extra>
          <a-divider />
          <a-button key="console" type="primary">查看订单</a-button>
          <a-button key="buy">再次下单</a-button>
        </template>
      </a-result>


    </div>

  </div>



</template>



<script>

//import router from '@/assets/router';
export default {
  name: 'OrderPaySuccess', 
  data(){
    return{
      orderId:"cq3333434"
    }
    
  },
  mounted() {
    document.title = this.$route.meta.title;

  },
  created() {
    this.orderId=this.$route.query.OrderId;
    
  },
  components: { 

  },
  computed: {


    },


  methods: {


  }
}
</script>
<style scoped>


</style>
