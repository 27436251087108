<template>

  <a-layout class="nd-layout_container nd-layout_large">

    <a-layout-sider width="200" theme="light"><leftMenu /></a-layout-sider>

    <a-layout-content :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '500px' }" >
      <a-divider orientation="left" orientation-margin="0px" >订单信息</a-divider>  
      <a-descriptions :column="1">
        <a-descriptions-item label="酒店名称">{{ orderDetail.HotelName }}</a-descriptions-item>
        <a-descriptions-item label="入离日期">{{ orderDetail.StrCheckIn }} / {{ orderDetail.StrCheckOut }}</a-descriptions-item>
        <a-descriptions-item label="订单金额">{{orderDetail.Currency}} {{orderDetail.TotalAmount}}</a-descriptions-item>
        <a-descriptions-item label="我司单号">{{orderDetail.OrderId}}</a-descriptions-item>
        <a-descriptions-item label="其他需求" v-if="orderDetail.Remark">{{orderDetail.Remark}}</a-descriptions-item>
        <a-descriptions-item label="取消政策">
            <span v-if="orderDetail.CancellationPolicy &&orderDetail.CancellationPolicy.Returnable" v-html="orderDetail.CancellationPolicy.Description.replace('||','<br>')"></span>
            <span v-else>不可取消，不可更改，NOSHOW扣全段费用</span>
        </a-descriptions-item>
      </a-descriptions>

      <a-divider orientation="left" orientation-margin="0px" >入住信息</a-divider>  
      <a-descriptions :column="1">
        <a-descriptions-item label="确 认 号" :col="2">{{ratePlans.SupplierConfirmNo}}</a-descriptions-item>
        <a-descriptions-item label="房/床型">{{ratePlans.RoomName}} {{ratePlans.RatePlanName}} {{ratePlans.BedType}}</a-descriptions-item>

        <a-descriptions-item label="入住间夜">{{ratePlans.RoomCount/ratePlans.NightCount}} 间 / {{ratePlans.NightCount  }}夜</a-descriptions-item>
        <a-descriptions-item label="住客姓名">{{guests.map(g => `${g.FirstName}/${g.LastName}`).join(",")}} </a-descriptions-item>

        <a-descriptions-item label="每日价格">
          <table class="nd-list-table">
                  <thead>
                      <tr>
                          <td>日期</td>
                          <td>房价</td>
                          <td>成人早餐</td>
                          <td>份数</td>
                          <td>儿童早餐</td>
                          <td>份数</td>
                          <td>加床价</td>
                          <td>数量</td>
                          <td>优惠</td>
                          <td>小计</td>
                      </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item,index) in ratePlanDetails" :key="`room_price_`+index">   
                      <td>{{item.PriceDate}}</td>    
                      <td>{{item.RoomPriceSale}}</td>    
                      <td>{{item.BreakfastSale}}</td>    
                      <td>{{item.BreakfastCount}}</td>    
                      <td>{{item.BreakfastChildSale}}</td>    
                      <td>{{item.BreakfastChildCount}}</td>    
                      <td>{{item.BedPriceSale}}</td>    
                      <td>{{item.BedCount}}</td>    
                      <td>-{{item.Discount}}</td>    

                      <td>{{
                      item.RoomPriceSale-item.Discount
                      
                      }}</td>
                    </tr>
                  </tbody>
              </table>

        </a-descriptions-item>


   
      </a-descriptions>

      <a-divider orientation="left" orientation-margin="0px" >注意事项</a-divider> 
      <a-descriptions :column="1">
        <a-descriptions-item :contentStyle="{lineHeight: '32px'}">
          1. 入住时间从14:00时，退房时间12:00之前。<br>
                  2. 请在前台办理入住时保密预订渠道，出示有效证件直接报名字办理入住。<br>
                  3. 如出现到店无房、订单确认后酒店告知无法安排入住情况，请及时联系为您预订酒店的旅行社或酒店代理。<br>
                  4. 如果未联系而自行人住其他酒店等，视为您放弃了订单保障权利。


        </a-descriptions-item>
      </a-descriptions>

    </a-layout-content>

  </a-layout>


</template>



<script>
import leftMenu from '@/components/leftMenu.vue';
//import dayjs from 'dayjs';
//import router from '@/assets/router';
export default {
  name: 'OrderDetail', 
  data(){
    return{
      orderId:"",
      orderDetail:{},
      ratePlans:{},
      ratePlanDetails:{},
      guests:[]

    }
    
  },
  mounted() {
    document.title = this.$route.meta.title;
// 假设这是在组件的生命周期钩子或者某个事件处理器中

  },
  created() { 
    this.orderId=this.$route.query.orderId;
    this.getOrderDetail()
  },
  components: { 
    leftMenu
  },
  computed: {

  }, 


  methods: {

    async getOrderDetail(){
        try {   
        const response = await this.$axios.get('/bookingDetail/'+this.orderId);
        console.log(response.data)
        if (response.data.RetCode === "Success") {

            this.orderDetail=response.data.BookingDetail;
            this.ratePlans=response.data.BookingDetail.RatePlans[0];
            this.guests=response.data.BookingDetail.RatePlans[0].Guests
            this.ratePlanDetails=response.data.BookingDetail.RatePlans[0].RatePlanDetails
            console.log(this.ratePlans)
        }


      } catch (error) {

        this.$error({
                title: '啊哦~',
                content: '获取数据失败',
                okType: 'danger',
                okText: '请刷新重试',
                onOk() {                 
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);  
                },
        });

      }finally {
             //   this.searchBtnLoading = false;
      }



    },


  }
}
</script>
<style scoped>

</style>
