<template>
      <section class="home_banner_section">
        <a-carousel autoplay v-model="bannerActive" height="500px"  :style="{ backgroundColor: `#FFF` }"  >
          <div :key="index" :id="index"  v-for="(item, index) in bannerCarousels">      
            <img class="carousel_img" :src="item.NewsPhoto"  />
          </div>
        </a-carousel>
      </section>
      <section class="home_login_section">        
        <loginForm />
      </section>

      <section class="home_hotHotels_section">
        <hotSaleHotel />
    </section>
</template>

<script>
import '@/assets/styles/home.css'
import hotSaleHotel from '@/components/hotSaleHotels.vue'
import loginForm from '@/components/loginForm.vue'
export default {
  name: 'HomePage',
  data(){
    return{
      bannerActive:0,
      bannerCarousels:[],
      copyRight:""
    }
    
  },
  mounted() {
    this.fetchCarousel();
    document.title = this.$route.meta.title;
  },
  components: {
    hotSaleHotel,
    loginForm
    
  },
  methods: {
    fetchCarousel(){
      this.$axios.post('/carousel')
      .then(response =>{
      
        if (response.data.RetCode == "Success") {
          this.bannerCarousels=response.data.Banners.filter(banner => banner.BannerType === "b2bindex1")
          this.bannerActive=0;
          this.copyRight=response.data.Company.RecordFiling;
        } else {
          console.log(response.data)
        }

      });
    },
    handleSelect(city) {
      console.log('Selected city:', city);
      // 处理选中的城市逻辑
    }
  }
}
</script>


<style scoped>
/* For demo */
:deep(.slick-slide) {
  text-align: center;
  height: 500px;
  line-height: 500px;
  background: #364d79;
  overflow: hidden;
}

</style>